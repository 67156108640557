import { Box, Collapse, Stack } from "@mui/material";
import { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { BrandingBar } from "../../modules/branding-bar/branding-bar";
import { ContentPreviews } from "../../modules/content-previews/content-previews";
import { CreateContentCard } from "../../modules/create-content-card/create-content-card";
import { PromptSummary } from "../../modules/create-content-card/prompt-summary";
import { Page } from "../../modules/layout/components/page";
import { useMultiformat } from "../../modules/multiformat/hooks/use-multi-format";

const CreateContentPreviewPage: FC = () => {
  const { data, loading, error } = useMultiformat();
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [showForm, setShowForm] = useState(false);
  const [useSelectedLogo, setUseSelectedLogo] = useState(false);

  const format = searchParams.get("format");

  const answers = useMemo(
    () => searchParams.getAll("answer").map((a) => ({ answer: a })),
    [searchParams],
  );

  const handleReset = useCallback(() => {
    setUseSelectedLogo(false);
  }, []);

  const handleLogoChange = useCallback(() => {
    setUseSelectedLogo(true);
  }, []);

  if (error) {
    return (
      <div>{t("Sorry, we encountered a problem trying to load this page. Please try again.")}</div>
    );
  }

  if (loading || !data) return null;

  return (
    <Page title={t("Create Content Preview | ChangeEngine")} sx={{ maxWidth: "xl" }}>
      <Box sx={{ position: "relative", minHeight: "2em" }}>
        <PromptSummary
          onClick={() => setShowForm(true)}
          sx={{ position: "absolute", width: "100%" }}
        />
        <Collapse in={showForm} sx={{ position: "relative" }}>
          <CreateContentCard onClose={() => setShowForm(false)} />
        </Collapse>
      </Box>
      <Stack gap={4} sx={(theme) => ({ [theme.breakpoints.down("md")]: { m: 3 }, m: 5 })}>
        <Stack direction="row-reverse">
          <BrandingBar
            compact={true}
            useSelectedLogo={useSelectedLogo}
            onLogoChange={handleLogoChange}
            onReset={handleReset}
          />
        </Stack>
        {format && data && (
          <ContentPreviews
            contentTypeName={format}
            answers={answers}
            useSelectedLogo={useSelectedLogo}
          />
        )}
      </Stack>
    </Page>
  );
};

export default CreateContentPreviewPage;

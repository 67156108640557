/* eslint-disable react-hooks/exhaustive-deps */
import { PostgrestClient, PostgrestResponse } from "@supabase/postgrest-js";
import Fuse from "fuse.js";
import { isString } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router";
import { useSupabase } from "../../../server/supabase/hooks";
import { Database } from "../../../server/supabase/types/database-definitions";
import { ISO8601, fromISO8601 } from "../../../utils/iso8601";
import { UUID } from "../../../utils/uuid";
import {
    FilteredMomentInfo,
    FilteredMomentState,
    SearchAttributes,
    useSetQueryParams,
} from "./utils";

export function useClientFilteredMoments<T extends SearchAttributes>(
  get: (client: PostgrestClient<Database>, account_id: UUID) => Promise<PostgrestResponse<T>>,
): FilteredMomentInfo<T> {
  const [allMoments, setAllMoments] = useState<T[]>();
  const defaultState = { loading: true, refresh: true };
  const [info, setInfo] = useState<FilteredMomentState<T>>(defaultState);
  const setQueryParams = useSetQueryParams();

  useSupabase(
    async ({ supabase, account_id }) => {
      if (!info.refresh) return;

      setInfo((prev) => ({ ...prev, loading: true, refresh: false }));

      const { data, error } = await get(supabase, account_id);
      if (error) return setInfo({ moments: [], loading: false, error: true, refresh: false });

      setAllMoments(data);
    },
    [info.refresh],
  );

  const filterFn = useMomentQueryFilterFunction<T>();

  useEffect(() => {
    if (allMoments === undefined) return;

    setInfo((prev) => ({ ...prev, loading: true, refresh: false, error: false }));
    setInfo({ moments: filterFn(allMoments), loading: false, error: false, refresh: false });
  }, [filterFn, allMoments]);

  return {
    loading: info.loading,
    moments: info.moments,
    error: info.error,
    setQueryParams,
    refresh: () => setInfo(defaultState),
  };
}

export function useMomentQueryFilterFunction<T extends SearchAttributes>(): (moments: T[]) => T[] {
  const [searchParams] = useSearchParams();

  const programs = searchParams.has("program") && searchParams.getAll("program");
  const statuses = searchParams.has("status") && searchParams.getAll("status");
  const channels = searchParams.has("channel") && searchParams.getAll("channel");
  const events = searchParams.has("event") && searchParams.getAll("event");
  const segments = searchParams.has("segment") && searchParams.getAll("segment");
  const q = searchParams.get("q");
  const start = searchParams.get("start");
  const end = searchParams.get("end");

  const fn = useMemo(() => {
    return (moments: T[]) => {
      let filteredMoments = moments;
      if (programs) {
        filteredMoments = filteredMoments.filter((m) => programs.includes(m.program_id || ""));
      }
      if (statuses) {
        filteredMoments = filteredMoments.filter((m) => statuses.includes(m.status || ""));
      }
      if (channels) {
        filteredMoments = filteredMoments.filter(
          (m) =>
            m.channel &&
            channels.map((x) => (x === "email" ? "work_email" : x)).includes(m.channel),
        );
      }

      if (events) {
        filteredMoments = filteredMoments.filter((m) =>
          events.includes(m.schedule?.event_key || ""),
        );
      }

      if (segments) {
        filteredMoments = filteredMoments.filter(
          (m) => m.segment === null || segments.includes(m.segment?.id || ""),
        );
      }

      if (isString(q)) {
        const fuse = new Fuse(filteredMoments, { keys: ["title"], useExtendedSearch: true });
        filteredMoments = fuse.search(q).map((result) => result.item);
      }

      if (isString(start)) {
        filteredMoments = filteredMoments.filter((m) =>
          m.when ? fromISO8601(m.when) >= fromISO8601(start as ISO8601) : true,
        );
      }

      if (isString(end)) {
        filteredMoments = filteredMoments.filter((m) =>
          m.when ? fromISO8601(m.when) <= fromISO8601(end as ISO8601) : true,
        );
      }

      return filteredMoments;
    };
  }, [
    JSON.stringify(programs),
    JSON.stringify(statuses),
    JSON.stringify(channels),
    JSON.stringify(events),
    JSON.stringify(segments),
    q,
    start,
    end,
  ]);

  return fn;
}

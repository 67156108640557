import { Box, LinearProgress, Stack } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useAnalytics } from "../../modules/analytics/analytics";
import { useBrandKit } from "../../modules/brand-kit/hooks/use-brand-kit";
import { useResetBrandingImageType } from "../../modules/brand-kit/hooks/use-reset-branding-image-type";
import { BrandingBar } from "../../modules/branding-bar/branding-bar";
import { DiscoverCarousels } from "../../modules/discover/carousel/discover-carousels";
import { DiscoverPrograms } from "../../modules/discover/discover-programs";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { libraryAtom } from "../../modules/discover/library/store";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { SearchField } from "../../modules/search/search-field";

const DiscoverPage: FC = () => {
  const { t } = useTranslation();

  useResetBrandingImageType();
  useDiscoverLibraryLoader();
  const library = useAtomValue(libraryAtom);

  const navigate = useNavigate();
  const brandKit = useBrandKit();

  const { gaEvent } = useAnalytics();

  const handleSubmit = useCallback(
    (value: string) => {
      gaEvent("discover_search", { search_term: value });
      void navigate({
        pathname: "/discover/search",
        search: `?${new URLSearchParams({ q: value }).toString()}`,
      });
    },
    [gaEvent, navigate],
  );

  if (!brandKit) return null;

  const { branding, loading } = brandKit;

  return (
    <Page title={t("Discover | ChangeEngine")}>
      <PageHeader pb={0}>
        <PageHeading heading={t("Discover")} />
      </PageHeader>
      {!loading && <BrandingBar visualStyleSelector />}
      <PageContent maxWidth="100%">
        <Stack direction="column" spacing={2}>
          <Box sx={{ minWidth: 200, maxWidth: 510, mt: 4 }}>
            <SearchField onSubmit={handleSubmit} defaultValue={""} label={t("Search Templates")} />
          </Box>
          {loading && library !== null ? (
            <LinearProgress />
          ) : (
            <>
              <DiscoverPrograms />
              <DiscoverCarousels brandKitSelections={branding} />
            </>
          )}
        </Stack>
      </PageContent>
    </Page>
  );
};

export default DiscoverPage;

import { SwapVert } from "@mui/icons-material";
import { Box, Button, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { throttle } from "lodash-es";
import { ReactElement, useRef, useState } from "react";
import { useSearchParams } from "react-router";

export interface Props {
  onChange: (sort: string) => void;
}

export const MomentSort = (): ReactElement => {
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const ref = useRef();
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedSort = searchParams.get("sort") ?? "schedule";

  const delayCloseDialog = throttle(() => setOpenPopover(false), 500, {
    leading: false,
    trailing: true,
  });

  const sortTypes: { [key: string]: string } = {
    title: "Title",
    schedule: "Schedule",
  };

  const setSortQuery = (sortValue: string): void => {
    searchParams.delete("sort");
    searchParams.set("sort", sortValue);
    setSearchParams(searchParams);
  };

  const handleChange = (newValue: string): void => {
    setSortQuery(newValue);
    setOpenPopover(false);
  };

  const renderItem = ([key, value]: [key: string, value: string]): ReactElement => {
    const selected = key === selectedSort;
    return (
      <MenuItem key={key} selected={selected} onClick={() => handleChange(key)}>
        <ListItemText>{value}</ListItemText>
      </MenuItem>
    );
  };

  return (
    <>
      <Box ref={ref}>
        <Button
          data-analytics-id="sent-moments-sort"
          onClick={() => setOpenPopover(true)}
          sx={{ color: "action.active" }}
          aria-label="filterButton"
        >
          <SwapVert sx={{ color: "action.active", mr: 1, my: 0.5 }} />
          {sortTypes[selectedSort]}
        </Button>
      </Box>
      <Menu open={openPopover} onClose={() => setOpenPopover(false)} anchorEl={ref.current}>
        <MenuList onMouseEnter={() => delayCloseDialog.cancel()}>
          {Object.entries(sortTypes).map(renderItem)}
        </MenuList>
      </Menu>
    </>
  );
};

import { ColorizeRounded } from "@mui/icons-material";
import { Button } from "@mui/material";
import { FC, useCallback } from "react";
import useEyeDropper from "use-eye-dropper";

export const EyeDropper: FC<{ onChange: (color: string) => void }> = ({ onChange }) => {
  const { open, isSupported } = useEyeDropper();

  const pickColor = useCallback(() => {
    // Using async/await (can be used as a promise as-well)
    const openPicker = async (): Promise<void> => {
      const pickedColor = await open();
      onChange(pickedColor.sRGBHex);
    };
    void openPicker();
  }, [onChange, open]);

  return (
    <>
      {isSupported() && (
        <Button
          data-analytics-id="colour-picker-popover-eye-dropper"
          onClick={pickColor}
          color="primary"
          variant="outlined"
        >
          <ColorizeRounded />
        </Button>
      )}
    </>
  );
};

import { Box, Button, Collapse, IconButton, TextField } from "@mui/material";
import { debounce } from "lodash-es";
import { ReactElement, useRef, useState } from "react";
import { useSearchParams } from "react-router";
import { Search as SearchIcon } from "../../styles/icons/search";
import { X } from "../../styles/icons/x";

export const Search = (): ReactElement => {
  const [open, setOpen] = useState(false);
  const searchIconRef = useRef(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = debounce((value: string): void => {
    // delete param if value is empty
    value ? searchParams.set("q", value) : searchParams.delete("q");
    setSearchParams(searchParams);
  }, 500);

  const searchValue = searchParams.get("q") || "";

  const handleCancel = (): void => {
    setOpen(false);
    handleChange("");
  };

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
      }}
    >
      {!open && (
        <Button
          data-analytics-id="sent-moments-search"
          onClick={() => setOpen(!open)}
          ref={searchIconRef}
        >
          <SearchIcon fontSize="small" sx={{ color: "action.active", my: 0.5 }} />
        </Button>
      )}

      <Collapse orientation="horizontal" in={open || !!searchValue}>
        <Box sx={{ mr: 2 }} key={searchValue}>
          <TextField
            fullWidth
            inputRef={(input: HTMLElement) => input && input.focus()}
            variant="standard"
            placeholder="Search your Moments"
            defaultValue={searchValue}
            onChange={(e) => handleChange(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton
                  data-analytics-id="sent-moments-search-cancel"
                  edge="end"
                  onClick={handleCancel}
                  data-testid="cancelButton"
                >
                  <X fontSize="small" />
                </IconButton>
              ),
            }}
          />
        </Box>
      </Collapse>
    </Box>
  );
};

import { Alert, CircularProgress, Dialog, DialogContent, DialogTitle, Link } from "@mui/material";
import { DataGridPro, GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-pro";
import { uniq } from "lodash-es";
import { FC, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { uuidv4 } from "../../../utils/uuid";
import { SupabasePersonConflictService } from "../server/supabase-person-conflict-service";

export const PersonConflictDataGrid: FC = () => {
  const { t } = useTranslation();
  const [showConflicts, setShowConflicts] = useState(false);

  const {
    data: personConflictData,
    loading,
    error,
  } = useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data, error: loadError } = await new SupabasePersonConflictService(supabase).getAll(
      account_id,
      { order: [{ column: "account_id" }] },
    );
    if (loadError) throw loadError;

    if (data.length === 0) return null;

    const keys: string[] = uniq(
      data.reduce(
        (prev, current) => [...prev, ...Object.keys(current)] as string[],
        [] as string[],
      ),
    );
    return { data: data, keys };
  }, []);

  if (loading) return <CircularProgress />;

  if (personConflictData === null) return null;

  if (error) return <Alert severity="warning">Unable to load person conflict data.</Alert>;

  return (
    <>
      <Alert severity="warning" sx={{ marginTop: 1 }}>
        <Trans i18nKey="Conflicts found in the last sync click here for more details">
          Conflicts found in the last sync,{" "}
          <Link href="#" onClick={() => setShowConflicts(true)}>
            click here
          </Link>{" "}
          for more details.
        </Trans>
      </Alert>
      <Dialog open={showConflicts} onClose={() => setShowConflicts(false)} fullWidth maxWidth="md">
        <DialogTitle>{t("Conflicting Records")}</DialogTitle>
        <DialogContent>
          <DataGridPro
            sx={{ display: "flex" }}
            autoHeight
            columns={columns}
            rows={personConflictData.data.map((datum) => ({
              ...datum,
              id: uuidv4(),
              record: JSON.stringify(datum["record"]),
            }))} // datagrid requires an ID
            slots={{
              toolbar: () => (
                <GridToolbarContainer>
                  <GridToolbarExport />
                </GridToolbarContainer>
              ),
            }}
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
            slotProps={{ toolbar: { allColumns: true } as any }}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const columns = [
  { field: "email", headerName: "email", flex: 0.5 },
  { field: "employee_id", headerName: "employee_id", flex: 0.5 },
  { field: "conflicting_field", headerName: "conflicting_field", flex: 0.5 },
  { field: "record", headerName: "record" },
  { field: "last_seen_at", headerName: "last_seen_at", flex: 1 },
];

import { isEmpty } from "lodash-es";
import { useCallback } from "react";
import { useToken } from "../../../server/auth/use-token";
import { useFlags } from "../../../server/optimizely";
import { useSupabaseCallback, useSupabaseLoader } from "../../../server/supabase/hooks";
import { convertEmailSvgImage } from "../../../utils/convert-svg-image";
import { useAsyncStateCallback } from "../../../utils/hooks/use-async-state-callback";
import { UUID } from "../../../utils/uuid";
import { SerialisedEditorDesign } from "../../message/editor/types/serialised-editor-design";
import { convertToMjmlString } from "../../message/editor/utils/convert-to-mjml";
import { convertSettingsToMjml } from "../../message/editor/utils/convert-to-mjml/layout";
import { EmailDesign, SupabaseEmailDesignService } from "../server/supabase-email-design-service";
import { DEFAULT_EMAIL_DESIGN_NAME, EmailDesignSchema } from "../types/email-design-type";
import { makeHeaderSerializedState } from "../util";

export function useEmailDesign(
  id?: UUID,
): ReturnType<typeof useSupabaseLoader<EmailDesign | null>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseEmailDesignService(supabase).maybeGet(
        id ?? account_id,
      );

      if (error) throw new Error(error.message);

      return data;
    },
    [id],
  );
}

export function useEmailDesigns(): ReturnType<typeof useSupabaseLoader<EmailDesign[]>> {
  return useSupabaseLoader(async ({ supabase, account_id }) => {
    const { data: designs, error: designError } = await new SupabaseEmailDesignService(
      supabase,
    ).getAll(account_id, {
      filter: [{ key: "deleted_at", operator: "is", value: null }],
      order: [{ column: "name" }],
    });

    if (designError || !designs) throw new Error(designError?.message ?? "Failed to load designs");

    return designs;
  }, []);
}

export function useEmailDesignActions(existing?: EmailDesign): {
  save: ReturnType<
    typeof useAsyncStateCallback<Partial<EmailDesign> | undefined, [UUID, EmailDesignSchema]>
  >;
  archive: ReturnType<typeof useAsyncStateCallback<boolean | undefined, [UUID]>>;
} {
  const {
    flags: { use_mso_bullet_formatting },
  } = useFlags("use_mso_bullet_formatting");
  const getToken = useToken();
  const convertSvgImage = useCallback(
    async (url: string | undefined) => {
      if (!url) return "";

      const logoSrc = await convertEmailSvgImage(url, getToken);

      if (!logoSrc?.success) {
        throw new Error("Failed to convert SVG in email header.");
      }

      return logoSrc.url;
    },
    [getToken],
  );

  const save = useAsyncStateCallback(
    useSupabaseCallback(
      async ({ supabase, account_id }, id: UUID, updates: EmailDesignSchema) => {
        const serializedHeader =
          updates.header && (await makeHeaderSerializedState(updates.header, convertSvgImage));
        const serializedFooter = updates.footer as SerialisedEditorDesign;

        const { is_default, name: newName, ...rest } = updates;

        const name = isEmpty(newName)
          ? existing?.name
            ? existing.name
            : DEFAULT_EMAIL_DESIGN_NAME
          : newName;

        const upsertData: Partial<EmailDesign> = {
          ...rest,
          name,
          id,
          styles_mjml: convertSettingsToMjml(updates, use_mso_bullet_formatting),
          header_mjml: serializedHeader
            ? convertToMjmlString(
                serializedHeader,
                account_id,
                "header",
                updates.layout_settings &&
                  updates.header &&
                  updates.layout_settings?.max_paper_width - updates.header?.padding,
                updates.header?.hide_image_url_from_notification,
              )
            : null,
          footer_mjml:
            serializedFooter?.root &&
            convertToMjmlString(
              serializedFooter,
              account_id,
              "footer",
              updates.layout_settings &&
                updates.layout_settings?.max_paper_width -
                  updates.layout_settings?.paper_padding_side,
            ),
          footer: serializedFooter,
        };

        const service = new SupabaseEmailDesignService(supabase);

        const { data, error } = await service.upsert(upsertData);

        if (error) throw new Error(error.message);

        if (is_default && !existing?.is_default && upsertData.id)
          await service.setDefault(upsertData.id);

        return data;
      },
      [existing, convertSvgImage, use_mso_bullet_formatting],
    ),
    [],
  );

  const archive = useAsyncStateCallback(
    useSupabaseCallback(
      async ({ supabase }, new_email_design_id: UUID) => {
        if (!existing) throw new Error("No design to archive");

        const { data, error } = await new SupabaseEmailDesignService(supabase).archive(
          existing.id,
          new_email_design_id,
        );

        if (error) throw new Error(error.message);

        return data;
      },
      [existing],
    ),
    [],
  );

  return { save, archive };
}

import { atom } from "jotai";
import { multiformatAtom } from "./multiformat-atom";
import { ContentTypeOption } from "./types";

export const contentTypeOptionsAtom = atom<ContentTypeOption[] | undefined>((get) => {
  const multiformat = get(multiformatAtom);

  const sorting = [
    "Communication",
    "Logo Landscape",
    "Logo Square",
    "TV Display",
    "Certificate",
    "Zoom Background",
    "Poster",
    "Employee FAQ",
    "Manager One-Pager",
    "Demo New",
  ];

  return multiformat?.["content-types"]
    .sort((a, b) => sorting.indexOf(a["Content Type"]) - sorting.indexOf(b["Content Type"]))
    .map((contentType) => ({
      label: contentType["CE App Name"] ?? contentType["Content Type"],
      value: contentType["Content Type"],
    }));
});

import { Card, CardActionArea, CardContent, CardMedia, Chip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export const DiscoverCard: FC = () => {
  const { t } = useTranslation();
  return (
    <Card raised>
      <CardActionArea
        href="/discover"
        sx={{ height: "100%", position: "relative" }}
        draggable={false}
      >
        <Chip
          color="primary"
          label={t("Recommended")}
          sx={(theme) => ({
            bgcolor: theme.palette.primary.light,
            textTransform: "uppercase",
            height: "auto",
            fontWeight: "600",
            position: "absolute",
            top: theme.spacing(1),
            left: theme.spacing(1),
          })}
        />
        <CardMedia
          image="https://images.changeengine.com/ce-cards/discover-library.png"
          alt="Choose Template"
          component={"img"}
          height="150px"
          draggable={false}
        />
        <CardContent>
          <Typography variant="h6">{t("Best Practice Moments")}</Typography>
          <Typography variant="body1" mt={2}>
            {t("Discover Moments for every occasion in our carefully curated library")}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

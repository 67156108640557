import { PostgrestSingleResponse } from "@supabase/postgrest-js";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSupabaseCallback } from "../../../server/supabase/hooks";
import { Asset, AssetUpsert, SupabaseDigitalAssetService } from "../server/digital-asset-service";

export const useUpsertAssetCallback = (): ((
  update: AssetUpsert,
) => Promise<PostgrestSingleResponse<Asset> | undefined>) => {
  return useSupabaseCallback(async ({ supabase }, asset: AssetUpsert) => {
    return new SupabaseDigitalAssetService(supabase).upsert(asset);
  }, []);
};

export const useCreateAssetCallback = (): (({
  title,
  thumbnailUrl,
  contentType,
  projectId,
  pageId,
}: {
  title: string;
  thumbnailUrl?: string;
  contentType: string;
  projectId: string;
  pageId: string;
}) => Promise<Asset>) => {
  const upsert = useUpsertAssetCallback();
  return useCallback(
    async ({ title, thumbnailUrl, contentType, projectId, pageId }) => {
      const response = await upsert({
        project_id: projectId,
        title,
        content_type: contentType,
        page_id: pageId,
        thumbnail_url: thumbnailUrl || "",
      });
      if (response?.error || !response?.data) {
        throw new Error("Failed to save asset. Please try again");
      }
      return response.data;
    },
    [upsert],
  );
};

export const useUpdateAssetCallback = (): (({
  asset,
  title,
  thumbnailUrl,
  projectId,
}: {
  asset: Asset;
  title?: string;
  thumbnailUrl?: string;
  projectId?: string | null;
}) => Promise<Asset>) => {
  const { t } = useTranslation();
  const upsert = useUpsertAssetCallback();
  return useCallback(
    async ({ asset, title, thumbnailUrl, projectId }) => {
      const result = await upsert({
        ...asset,
        title: title ?? asset.title,
        thumbnail_url: thumbnailUrl ?? asset.thumbnail_url,
        project_id: projectId ?? asset.project_id,
        page_id: asset.page_id,
      });

      if (result?.error || !result?.data) {
        throw new Error(result?.error?.message || t("Unknown error: Failed to save asset"));
      }
      return result.data;
    },
    [upsert, t],
  );
};

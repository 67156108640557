import { useDebouncedCallback } from "@react-hookz/web";
import { FC } from "react";
import { useSearchParams } from "react-router";
import { SearchField, SearchFieldProps } from "./search-field";

type LiveSearchFieldProps = Omit<SearchFieldProps, "defaultValue"> & {
  searchParamName?: string;
};
export const LiveSearchField: FC<LiveSearchFieldProps> = ({ searchParamName = "q", ...props }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = useDebouncedCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      searchParams.set(searchParamName, e.target.value);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, searchParamName],
    300,
    5000,
  );

  return (
    <SearchField
      defaultValue={searchParams.get(searchParamName) ?? ""}
      onChange={handleChange}
      {...props}
    />
  );
};

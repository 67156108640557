import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router";
import { useFlags } from "../../../../server/optimizely";
import { useDialog } from "../../../../utils/hooks/use-dialog";
import { UUID } from "../../../../utils/uuid";
import { CopyDialog, CopyDialogProps } from "./copy-dialog";
import { CopyWarningDialog } from "./copy-warning-dialog";
import { CopyWarningDialogWithRename } from "./copy-warning-dialog-with-rename";

export function useCopyDialogs({ clonable, id }: { clonable: boolean | "warn_first"; id: UUID }): {
  handleCopy: () => void;
  dialogControl: ReturnType<typeof useDialog>;
  CopyDialogComponent?: FC<CopyDialogProps>;
} {
  const {
    flags: { copy_moment_dialog },
  } = useFlags("copy_moment_dialog");

  const navigate = useNavigate();

  const dialogControl = useDialog();
  const dialogComponent = useMemo(() => {
    if (clonable === "warn_first")
      return copy_moment_dialog ? CopyWarningDialogWithRename : CopyWarningDialog;
    else if (copy_moment_dialog) return CopyDialog;
    else return undefined;
  }, [clonable, copy_moment_dialog]);

  const handleCopy = useCallback(() => {
    if (dialogComponent) dialogControl.handleOpen();
    else void navigate(`/moments/copy/${id}`);
  }, [dialogComponent, dialogControl, id, navigate]);

  return { handleCopy, dialogControl: dialogControl, CopyDialogComponent: dialogComponent };
}

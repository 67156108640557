import { Info } from "@mui/icons-material";
import { Card, CardContent, CardMedia, Chip, Stack, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router";
import { GenerateMomentForm } from "./generate-form";

export const GenerateMomentCard: FC = () => {
  const { t } = useTranslation();

  return (
    <Card sx={{ gridColumnEnd: "span 3", position: "relative" }} raised draggable={false}>
      <Chip
        color="primary"
        label="Recommended"
        sx={(theme) => ({
          bgcolor: theme.palette.primary.light,
          textTransform: "uppercase",
          height: "auto",
          fontWeight: "600",
          position: "absolute",
          top: theme.spacing(1),
          left: theme.spacing(1),
        })}
      />
      <CardMedia
        image="https://images.changeengine.com/ce-cards/create-with-ai.png"
        alt="AI"
        component={"img"}
        height="150px"
        draggable={false}
      />
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography variant="h6">{t("Generate with AI")}</Typography>
          <Tooltip title={t("Learn more about Personal Tones")} placement="right">
            <Link
              to="https://help.changeengine.com/Creating-a-Personalized-Tone-for-Co-Pilot-a21a3f6f1d5141e293823ea4d377824b"
              target="_blank"
            >
              <Info color="info" fontSize="small" />
            </Link>
          </Tooltip>
        </Stack>
        <GenerateMomentForm />
      </CardContent>
    </Card>
  );
};

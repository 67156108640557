import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useNavigate } from "react-router";

const EmailDomainConfigurationPage: FC = () => {
  const navigate = useNavigate();

  useMountEffect(() => {void navigate("/manage/channels/email", { replace: true })});

  return <></>;
};

export default EmailDomainConfigurationPage;

import { atomFamily, atomWithDefault } from "jotai/utils";
import { isEqual } from "lodash-es";
import { getTokenAtom } from "../../../server/auth/store";
import { authHeader } from "../../../utils/auth-header";
import { debugLabel, gptModelAtom } from "../../generate/store";
import { selfDriving } from "../../self-driving/self-driving";

export const layoutConfigurationFamily = atomFamily(
  ({
    formatId,
    answer0,
    imgNum,
    cacheBuster,
  }: {
    formatId: string;
    answer0: string
    imgNum: string;
    cacheBuster?: string;
  }) =>
    debugLabel(
      `layoutConfigurationFamily(${formatId}, ${answer0}, ${imgNum}, ${cacheBuster})`,
      atomWithDefault(async (get) => {
        const { getToken } = get(getTokenAtom);
        const gptModel = get(gptModelAtom);
        const token = await getToken();

        if (!token || !gptModel) return;

        const { data, error } = await selfDriving.POST("/multiformat/{formatId}/image/{imgNum}", {
          body: {
            description: answer0,
          },
          params: {
            path: { formatId, imgNum },
            query: { model: gptModel, cacheBuster },
          },
          ...authHeader(token),
        });

        if (error) {
          throw error;
        }

        return data;
      }),
    ),

  isEqual,
);

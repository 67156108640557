import { useCallback } from "react";
import { useToken } from "../../../server/auth/use-token";
import { Asset } from "../../assets/server/digital-asset-service";
import { Editor, GetRenderResponse } from "../../design-huddle/types";
import { copyImage, getPageId } from "../../design-huddle/utils";
import { SaveAssetHandler } from "../types";

export const useSaveAssetCallback = ({
  editor,
  onSave,
  title,
  projectId,
  page,
}: {
  title: string;
  editor: Editor | null;
  onSave?: SaveAssetHandler;
  projectId: string;
  page: number;
}): (() => Promise<Asset | undefined>) => {
  const getToken = useToken();

  return useCallback(() => {
    return (async () => {
      if (!editor) {
        return await onSave?.({ title });
      }
      const pageId = await getPageId(projectId, page);

      const { url } = await new Promise<GetRenderResponse>((resolve, reject) => {
        editor.getRender({ max_height: 400, max_width: 400, page_id: pageId }, function (err, data) {
          if (err) {
            reject(err);
          } else {
            resolve(data);
          }
        });
      });
      const response = await copyImage(getToken, url, `asset:thumbnail:${projectId}:${Date.now()}`);

      return response.success
        ? await onSave?.({ title, thumbnailUrl: response.url, projectId, pageId })
        : await onSave?.({ title, projectId, pageId });
    })();
  }, [editor, getToken, onSave, page, projectId, title]);
};

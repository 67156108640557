import {
  IMjmlClassProps,
  MjmlAll,
  MjmlAttributes,
  MjmlBody,
  MjmlClass,
  MjmlDivider,
  MjmlFont,
  MjmlHead,
  MjmlImage,
  MjmlStyle,
  MjmlText,
} from "@faire/mjml-react";
import ReactDOMServer from "react-dom/server";
import { notEmpty } from "../../../../../utils/not-empty";
import { ButtonTemplateSettings } from "../../../../email-design/types/email-button-settings";
import { EmailDesignSchema } from "../../../../email-design/types/email-design-type";

// Styles taken from https://lists.cm, they _seem_ to help.
const MSO_BULLET_FORMATTING = `<!-- [if mso]>
ul {margin: 0 !important;}
li {margin-left: 40px !important;}
li.firstListItem {margin-top: 1px !important;}
li.lastListItem {margin-bottom: 1px !important;}
<![endif]-->`;

export function convertSettingsToMjml(
  settings: Partial<EmailDesignSchema>,
  use_mso_bullet_formatting: boolean,
): string {
  const styles = {
    h1: [
      maybeInclude("font-size", (val) => `${val}px`, settings.text_settings?.heading1?.font_size),
      maybeInclude("color", (val) => val, settings.text_settings?.heading1?.font_colour),
      maybeInclude(
        "font-weight",
        (val) => (val ? "bold" : "normal"),
        settings.text_settings?.heading1?.bold,
      ),
      maybeInclude("font-family", (val) => val, settings.text_settings?.header_font),
    ],
    h2: [
      maybeInclude("font-size", (val) => `${val}px`, settings.text_settings?.heading2?.font_size),
      maybeInclude("color", (val) => val, settings.text_settings?.heading2?.font_colour),
      maybeInclude(
        "font-weight",
        (val) => (val ? "bold" : "normal"),
        settings.text_settings?.heading2?.bold,
      ),
      maybeInclude("font-family", (val) => val, settings.text_settings?.header_font),
    ],
    h3: [
      maybeInclude("font-size", (val) => `${val}px`, settings.text_settings?.heading3?.font_size),
      maybeInclude("color", (val) => val, settings.text_settings?.heading3?.font_colour),
      maybeInclude(
        "font-weight",
        (val) => (val ? "bold" : "normal"),
        settings.text_settings?.heading3?.bold,
      ),
      maybeInclude("font-family", (val) => val, settings.text_settings?.header_font),
    ],
    h4: [
      maybeInclude("font-size", (val) => `${val}px`, settings.text_settings?.heading4?.font_size),
      maybeInclude("color", (val) => val, settings.text_settings?.heading4?.font_colour),
      maybeInclude(
        "font-weight",
        (val) => (val ? "bold" : "normal"),
        settings.text_settings?.heading4?.bold,
      ),
      maybeInclude("font-family", (val) => val, settings.text_settings?.header_font),
    ],
    paper: [maybeInclude("background-color", (val) => val, settings.layout_settings?.paper_colour)],
    paperBorder: [
      maybeInclude(
        "border",
        (val) =>
          val.width === 0
            ? "none"
            : `${val.width}px solid ${val.colour}
      `,
        settings.layout_settings?.border,
      ),
    ],
    header: [
      maybeInclude("background-color", (val) => val, settings.header?.backgroundColor),
      maybeInclude(
        "width",
        (val) => `${val}px`,
        settings.header?.fullWidth ? undefined : settings.layout_settings?.max_paper_width,
      ),
      maybeInclude("font-family", (val) => val, settings.text_settings?.header_font),
    ],
    outlook: {
      paper: [
        maybeInclude("background-color", (val) => val, settings.layout_settings?.paper_colour),
        maybeInclude("max-width", (val) => val, `${settings.layout_settings?.max_paper_width}px`),
      ],
      header: [
        maybeInclude("background-color", (val) => val, settings.header?.backgroundColor),
        maybeInclude(
          "max-width",
          (val) => `${val}px`,
          settings.header?.fullWidth ? undefined : settings.layout_settings?.max_paper_width,
        ),
      ],
      footer: [
        maybeInclude("background-color", (val) => val, settings.layout_settings?.paper_colour),
        maybeInclude("max-width", (val) => val, `${settings.layout_settings?.max_paper_width}px`),
      ],
    },
  };

  const data = ReactDOMServer.renderToString(
    <MjmlHead>
      <MjmlFont name={settings.text_settings?.font} />
      <MjmlAttributes>
        <MjmlAll
          font-family={settings.text_settings?.font}
          ico-font-family={settings.text_settings?.font}
          font-size={settings.text_settings?.font_size}
          color={settings.text_settings?.font_colour}
          line-height={
            settings.text_settings?.line_height
              ? `${settings.text_settings?.line_height}`
              : undefined
          }
        />
        <MjmlBody
          background-color={settings.layout_settings?.background_colour}
          width={settings.layout_settings?.max_paper_width}
        />
        {settings.layout_settings?.paragraph_padding !== undefined && (
          <>
            <MjmlText padding={`0 0 ${settings.layout_settings?.paragraph_padding}px 0`} />
            <MjmlImage padding={`0 0 ${settings.layout_settings?.paragraph_padding}px 0`} />
            <MjmlDivider padding={`0 0 ${settings.layout_settings?.paragraph_padding}px 0`} />
          </>
        )}

        {settings.header?.fullWidth && <MjmlClass name="header" full-width={"full-width"} />}

        {settings.header?.padding !== undefined && (
          <MjmlClass name="header" padding={`${settings.header?.padding}px`} />
        )}
        {settings.layout_settings !== undefined && (
          <>
            <MjmlClass
              name="paper"
              padding-left={`${settings.layout_settings?.paper_padding_side}px`}
              padding-right={`${settings.layout_settings?.paper_padding_side}px`}
              padding-top={`${settings.layout_settings?.paper_padding_top}px`}
              padding-bottom={`${settings.layout_settings?.paper_padding_bottom}px`}
            />
            <MjmlClass
              name="footer"
              padding-left={`${settings.layout_settings?.paper_padding_side}px`}
              padding-right={`${settings.layout_settings?.paper_padding_side}px`}
              padding-bottom={`${settings.layout_settings?.paper_padding_bottom}px`}
              padding-top={`${settings.layout_settings?.paper_padding_top}px`}
            />
          </>
        )}
        {settings.button_settings?.primary_button && (
          <MjmlClass
            name="primary_button"
            {...buttonProps(
              settings.button_settings.primary_button,
              settings.layout_settings?.paragraph_padding,
            )}
          />
        )}
        {settings.button_settings?.secondary_button && (
          <MjmlClass
            name="secondary_button"
            {...buttonProps(
              settings.button_settings.secondary_button,
              settings.layout_settings?.paragraph_padding,
            )}
          />
        )}
        {settings.divider_settings?.primary && (
          <MjmlClass
            name="primary_divider"
            borderWidth={`${settings.divider_settings.primary.width}px`}
            borderColor={settings.divider_settings.primary.color}
            borderStyle={settings.divider_settings.primary.style}
          />
        )}
        {settings.divider_settings?.secondary && (
          <MjmlClass
            name="secondary_divider"
            borderWidth={`${settings.divider_settings.secondary.width}px`}
            borderColor={settings.divider_settings.secondary.color}
            borderStyle={settings.divider_settings.secondary.style}
          />
        )}
        <MjmlClass name="section" padding="0" margin="0" />
        <MjmlClass
          name="column"
          padding={`${
            settings.layout_settings?.column_spacing_type === "padding"
              ? settings.layout_settings.column_padding
              : 0
          }px`}
          innerBorder={`solid ${settings.layout_settings?.paper_colour} ${
            settings.layout_settings?.column_spacing_type === "spacing"
              ? settings.layout_settings.column_spacing
              : 0
          }px`}
        />
      </MjmlAttributes>
      <MjmlStyle inline>
        {`
          h1 {
            ${styles.h1.filter(notEmpty).join(";\n")};
          }
          h2 {
            ${styles.h2.filter(notEmpty).join(";\n")};
          }
          h3 {
            ${styles.h3.filter(notEmpty).join(";\n")};
          }
          h4 {
            ${styles.h4.filter(notEmpty).join(";\n")};
          }
          .paper {
            ${styles.paper.filter(notEmpty).join(";\n")};
          }
          .paper > table {
            ${styles.outlook.paper.filter(notEmpty).join(";\n")};
            ${styles.paperBorder.filter(notEmpty).join(";\n")};
          }
          .header {
            ${styles.header.filter(notEmpty).join(";\n")};
          }
          .header > table {
            ${styles.outlook.header.filter(notEmpty).join(";\n")};
          }
          .footer > table {
            ${styles.outlook.footer.filter(notEmpty).join(";\n")};
          }
          h1, h2, h3, h4, p, ul, ol {
            margin: 0;
          }
          ol, ul {
            list-style-position: inside;
          }
          ol.nestedlist_1 {
            list-style-type: upper-alpha;
          }
          ol.nestedlist_2 {
            list-style-type: lower-alpha;
          }
          ol.nestedlist_4 {
            list-style-type: upper-roman;
          }
          ol.nestedlist_4 {
            list-style-type: lower-roman;
          }
          .btn-full-width a { display: block !important; }
          ${use_mso_bullet_formatting ? MSO_BULLET_FORMATTING : ""}
        `}
      </MjmlStyle>
    </MjmlHead>,
  );
  return data;
}

function maybeInclude<T>(key: string, valueFormat: (val: T) => string, value?: T): string | null {
  return value !== undefined ? `${key}: ${valueFormat(value)}` : null;
}

function buttonProps(
  buttonSettings: ButtonTemplateSettings,
  paragraphPadding?: number,
): IMjmlClassProps {
  return {
    padding: `0 0 ${paragraphPadding ?? 0}px 0`,
    color: buttonSettings.color,
    backgroundColor: buttonSettings.background_color,
    fontSize: `${buttonSettings.font_size}px`,
    borderRadius: `${buttonSettings.border_radius}px`,
    border:
      buttonSettings.border && buttonSettings.color
        ? `${buttonSettings.border}px solid ${buttonSettings.color}`
        : undefined,
  };
}

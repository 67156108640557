import { useAtomValue } from "jotai";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFlag } from "../../server/optimizely";
import { CeDropdown } from "../generic/components/form-fields/ce-dropdown";
import { contentTypeOptionsAtom } from "../multiformat/store/content-type-options-atom";
import { CreateContentRequest } from "../multiformat/store/types";

export const ContentTypeSelect: FC = () => {
  const [mfai_demo_new] = useFlag("mfai_demo_new");
  const contentTypeOptions = useAtomValue(contentTypeOptionsAtom);
  const options = mfai_demo_new ? contentTypeOptions : contentTypeOptions?.filter(o => o.value !== "Demo New");
  const { t } = useTranslation();
  return (
    <CeDropdown<CreateContentRequest>
      label={t("Content Type")}
      name="format"
      loading={!contentTypeOptions}
      options={options ?? []}
    />
  );
};

import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router";

const SlackPage: FC = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  useMountEffect(() => {void navigate(`/manage/channels/slack${search}`, { replace: true })});

  return <></>;
};

export default SlackPage;

import { FC } from "react";
import { useParams } from "react-router";
import { useFlag } from "../../server/optimizely";
import GenerateMomentPage from "../moments/generate/generate-moment-page";
import NotFound from "../not-found";

const RecipePage: FC = () => {
  const { id } = useParams();
  const [ai_recipes] = useFlag("ai_recipes");
  if (!ai_recipes) return <NotFound />;
  return <GenerateMomentPage recipeId={id} />;
};

export default RecipePage;

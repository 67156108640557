import { Launch } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Fade, IconButton, Popper, Stack, Typography } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { useAtomValue, useSetAtom } from "jotai";
import { loadable } from "jotai/utils";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router";
import { config } from "../../config";
import { useAnalytics } from "../../modules/analytics/analytics";
import { getBrandKitStyle, useBrandKit } from "../../modules/brand-kit/hooks/use-brand-kit";
import { ContentTone } from "../../modules/brand-kit/server/brand-kit-service";
import { SupabaseBrandToneService } from "../../modules/brand-kit/server/brand-tone-service";
import { brandingAtom } from "../../modules/brand-kit/store";
import { BrandingBar } from "../../modules/branding-bar/branding-bar";
import { SupabaseCustomEmailService } from "../../modules/channels/email/server/supabase-custom-email-service";
import { ChannelName } from "../../modules/channels/types";
import { useGetDesignHuddleAccessToken } from "../../modules/design-huddle/use-design-huddle-access-token";
import { useCreateDesignHuddleProject } from "../../modules/design-huddle/use-design-huddle-project-create";
import { getPageNumber, imageURL } from "../../modules/design-huddle/utils";
import { useDiscoverLibraryLoader } from "../../modules/discover/hooks/use-libary-loader";
import { libraryQueryAtom, libraryTemplateAtom } from "../../modules/discover/library/store";
import { LibraryTemplate } from "../../modules/discover/library/types";
import { toImportMoment } from "../../modules/discover/transform";
import { useEmailDesigns } from "../../modules/email-design/hooks/use-email-design";
import { accountIdAtom, brandToneAtom } from "../../modules/generate/store";
import { FixedFooter } from "../../modules/generic/fixed-footer";
import { useAccount } from "../../modules/generic/hooks/use-account";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { EMAIL_CHANNELS } from "../../modules/moment/component/channel/channel";
import { SupabaseMomentService } from "../../modules/moment/server/supabase-moment-service";
import { Breadcrumbs } from "../../modules/tabbed-programs/breadcrumbs";
import { useSupabaseCallback } from "../../server/supabase/hooks";
import { WorkEmailIcon } from "../../styles/icons/email";
import { SlackIcon } from "../../styles/icons/slack";
import { TeamsIcon } from "../../styles/icons/teams";
import { usePopover } from "../../utils/hooks/use-popover";

import { useResetBrandingImageType } from "../../modules/brand-kit/hooks/use-reset-branding-image-type";
import { Markdown } from "../../modules/discover/markdown";
import { discoverTemplateAtom } from "../../modules/discover/store";
import { TemplatePreview } from "../../modules/template-preview/template-preview";
import { useSupabase } from "../../server/supabase/hooks";
import { UUID } from "../../utils/uuid";

const DiscoverTemplatePreviewPage: FC = () => {
  useResetBrandingImageType();
  useDiscoverLibraryLoader();
  useGetDesignHuddleAccessToken();
  const { program_slug, template_slug } = useParams<{
    program_slug: string;
    template_slug: string;
  }>();

  const [params, setSearchParams] = useSearchParams();
  const contentTone = useMemo(() => params.get("contentTone") as ContentTone, [params]);

  const setTemplateQuery = useSetAtom(libraryQueryAtom);

  const setSelectedBranding = useSetAtom(brandingAtom);
  const { branding, loading } = useBrandKit();

  const setBrandTone = useSetAtom(brandToneAtom);
  const setAccountId = useSetAtom(accountIdAtom);

  const brandToneId = params.get("brand_tone");

  useSupabase(
    async ({ supabase, account_id }) => {
      setAccountId(account_id);
      if (!brandToneId || brandToneId === "") return;

      const { data } = await new SupabaseBrandToneService(supabase).get(brandToneId as UUID);
      setBrandTone(data);
    },
    [brandToneId, setAccountId, setBrandTone],
  );

  useEffect(() => {
    setSearchParams(
      (searchParams) => {
        if (loading) return searchParams;

        if (searchParams.get("contentTone") === null) {
          searchParams.set("contentTone", branding.default_tone);
        }
        return searchParams;
      },
      {
        replace: true,
      },
    );
  }, [setSearchParams, branding, loading]);

  useEffect(() => {
    program_slug && template_slug && setTemplateQuery({ program_slug, template_slug });
  }, [setTemplateQuery, program_slug, template_slug]);

  const handleImageSelect = useCallback(
    (num: number) => {
      const branding_type = getBrandKitStyle(num);
      branding &&
        setSelectedBranding({
          ...branding,
          branding_type: {
            type: "standard",
            value: branding_type,
          },
        });
    },
    [branding, setSelectedBranding],
  );

  const handleContentToneChange = useCallback(
    (value: ContentTone | "custom"): void => {
      setSearchParams((searchParams) => {
        searchParams.set("contentTone", value);
        return searchParams;
      });
    },
    [setSearchParams],
  );

  const handleBrandToneChange = useCallback(
    (tone: UUID) => {
      params.set("brand_tone", tone);
      setSearchParams(params);
    },
    [params, setSearchParams],
  );

  const template = useAtomValue(libraryTemplateAtom);

  return (
    <Page title={template?.title ?? "ChangeEngine"}>
      <Header />

      <BrandingBar />
      <PageContent centerContent={true} pt={0}>
        <TemplatePreview
          loadableTemplateAtom={loadable(discoverTemplateAtom)}
          store="discover"
          onContentToneChange={handleContentToneChange}
          onBrandToneChange={handleBrandToneChange}
          onImageSelect={handleImageSelect}
          contentTone={contentTone}
          brandTone={brandToneId as UUID}
          designHuddle={true}
        />
      </PageContent>
      <FixedFooter
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          py: 2,
        }}
      >
        <LaunchButton contentTone={contentTone} />
      </FixedFooter>
    </Page>
  );
};

export default DiscoverTemplatePreviewPage;

const Header: FC = () => {
  const template = useAtomValue(libraryTemplateAtom);
  const title = template?.title ?? "";

  const contextButton = usePopover<HTMLButtonElement>();
  return (
    <PageHeader title={`${title} | ChangeEngine`}>
      <Breadcrumbs
        crumbs={[
          { title: "Discover", to: "/discover" },
          {
            title: template?.program.name ?? "",
            to: `/discover/${template?.program.slug}`,
          },
          {
            title,
          },
        ]}
      />
      <Stack gap={0}>
        <PageHeading heading={template?.title ?? "..."} />

        <Stack
          direction="row"
          gap={1}
          alignItems="center"
          sx={{
            ":hover": {
              ".MuiSvgIcon-root": {
                color: "primary.main",
              },
            },
          }}
        >
          <Typography
            variant="subtitle1"
            color="text.secondary"
            maxWidth={"80%"}
            overflow={"hidden"}
            noWrap
          >
            {template?.context}
          </Typography>
          <Popper anchorEl={contextButton.anchorRef.current} open={contextButton.open} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <Box
                  onMouseEnter={contextButton.handleToggle}
                  onMouseLeave={contextButton.handleToggle}
                  px={4}
                  py={2}
                  bgcolor="background.paper"
                  boxShadow={4}
                  borderRadius={1}
                  border="1px solid"
                  maxWidth={"30vw"}
                  maxHeight="60vh"
                  overflow="scroll"
                  fontSize={"0.9rem"}
                >
                  <Markdown content={template?.context ?? ""} />
                  <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%" />
                </Box>
              </Fade>
            )}
          </Popper>
          <IconButton
            data-analytics-id="discover-template-context"
            ref={contextButton.anchorRef}
            size="small"
            onMouseEnter={contextButton.handleToggle}
            onMouseLeave={contextButton.handleToggle}
          >
            <Launch color="disabled" />
          </IconButton>
        </Stack>
      </Stack>
    </PageHeader>
  );
};

const LaunchButton: FC<{ contentTone: ContentTone }> = ({ contentTone }) => {
  const { t } = useTranslation();
  const createButtonPopover = usePopover<HTMLButtonElement>();
  const [launching, setLaunching] = useState(false);
  const { gaEvent } = useAnalytics();
  const navigate = useNavigate();
  const { account } = useAccount();

  const createProject = useCreateDesignHuddleProject();
  const { data: emailDesigns } = useEmailDesigns();

  const templateLoader = useAtomValue(loadable(discoverTemplateAtom));
  const libraryTemplateLoader = useAtomValue(loadable(libraryTemplateAtom));
  const libraryTemplate =
    libraryTemplateLoader.state === "hasData" ? libraryTemplateLoader.data : undefined;

  const branding = useAtomValue(brandingAtom);

  const launch = useSupabaseCallback(
    async ({ supabase, account_id }, channel: ChannelName) => {
      const template = templateLoader.state === "hasData" ? templateLoader.data : undefined;
      const selectedImage = template?.selectedImage;

      const imageLoader = selectedImage !== undefined && template?.images?.[selectedImage];
      const imageVariant =
        (imageLoader && imageLoader.state === "hasData" && imageLoader.data) || undefined;

      if (!template || !libraryTemplate) return;

      setLaunching(true);

      gaEvent("discover_preview", {
        action: "use_template",
        value: template?.slug,
        method: channel ?? "",
      });

      const projectData = imageVariant?.dhProject
        ? {
            project_id: imageVariant.dhProject.project_id,
            preview_url: imageVariant.dhProject.thumbnail_url,
          }
        : await createProject(template.img?.template_code, branding, imageVariant);
      const projectId = projectData?.project_id ?? null;

      const emailDesignId = emailDesigns?.find((e) => e.is_default)?.id;

      const isEmail = EMAIL_CHANNELS.includes(channel);
      const from = isEmail
        ? (await new SupabaseCustomEmailService(supabase).getDefault()).data?.address ||
          config.defaultEmailFrom
        : undefined;

      const customContent =
        template.content.custom?.state === "hasData" ? template.content.custom?.data : null;

      const finalTemplate: LibraryTemplate = {
        ...libraryTemplate,
        content: {
          ...libraryTemplate.content,
          direct:
            (template.content.direct.state === "hasData"
              ? template.content.direct.data?.data.response.content
              : undefined) ?? libraryTemplate.content.direct,
          professional:
            (template.content.professional.state === "hasData"
              ? template.content.professional.data?.data.response.content
              : undefined) ?? libraryTemplate.content.professional,
          casual:
            (template.content.casual.state === "hasData"
              ? template.content.casual.data?.data.response.content
              : undefined) ?? libraryTemplate.content.casual,
          ...(customContent && { custom: customContent.data.response.content }),
        },
      };

      const momentTemplate = toImportMoment(
        finalTemplate,
        contentTone,
        emailDesignId,
        projectId
          ? {
              project_id: projectId,
              account_id,
              page_number: getPageNumber(branding),
            }
          : undefined,
        channel,
        projectId ? imageURL(account_id, projectId) : undefined,
        from,
        account?.enable_teams,
      );

      const { data, error } = await new SupabaseMomentService(supabase).importMoment(
        momentTemplate,
      );

      setLaunching(false);
      if (error) toast(error.message);
      else void navigate(`/moments/${data}`);
    },
    [
      templateLoader,
      libraryTemplate,
      gaEvent,
      createProject,
      branding,
      emailDesigns,
      contentTone,
      navigate,
      account,
    ],
  );

  const sliderRef = useRef<HTMLDivElement>(null);

  const channels = useMemo(() => {
    if (!account) return [];
    const { enable_slack, enable_teams } = account;
    return [
      ...(enable_slack
        ? [{ name: "Slack", channel_name: "slack" as const, icon: <SlackIcon /> }]
        : []),
      ...(enable_teams
        ? [{ name: "Teams", channel_name: "teams" as const, icon: <TeamsIcon /> }]
        : []),
      { name: "Email", channel_name: "work_email" as const, icon: <WorkEmailIcon /> },
    ];
  }, [account]);

  const handleOpen = useCallback(() => {
    createButtonPopover.handleOpen();
    setTimeout(() => {
      if (sliderRef.current) {
        sliderRef.current.style.height = channels.length * 56 + "px";
      }
    });
  }, [channels, createButtonPopover]);

  if (!account) return null;

  return (
    <Box onMouseOut={createButtonPopover.handleClose}>
      <Popper
        sx={{ zIndex: 1500 }}
        onMouseOut={createButtonPopover.handleClose}
        onMouseOver={createButtonPopover.handleOpen}
        open={!launching && createButtonPopover.open}
        anchorEl={createButtonPopover.anchorRef.current}
        placement="top-start"
      >
        <Box
          ref={sliderRef}
          width={createButtonPopover.anchorRef.current?.offsetWidth}
          display="flex"
          flexDirection="row-reverse"
          borderRadius="10px 10px 0 0"
          bgcolor="primary.light"
          overflow="hidden"
          height={0}
          sx={{
            transition: "height 0.2s ease-in-out",
          }}
        >
          <Stack direction="column" spacing={1} width="100%" m={2}>
            {channels.map(({ name, channel_name, icon }) => (
              <Button
                data-analytics-id={`discover-template-launch-${channel_name}`}
                key={channel_name}
                startIcon={icon}
                variant="outlined"
                fullWidth
                sx={{
                  backgroundColor: "#fff",
                  "&:hover": {
                    backgroundColor: "rgba(255,255,255,0.8)",
                  },
                }}
                onClick={() => {
                  void launch(channel_name).catch(console.error);
                  createButtonPopover.handleClose();
                }}
              >
                {name}
              </Button>
            ))}
          </Stack>
        </Box>
      </Popper>
      <LoadingButton
        data-analytics-id="discover-template-launch"
        loading={launching}
        endIcon={
          <ArrowRightIcon
            sx={{
              transition: "transform 0.2s ease-in-out",
              ...(createButtonPopover.open && {
                transform: "rotate(-90deg)",
              }),
            }}
          />
        }
        color="primary"
        variant="contained"
        sx={(theme) => ({
          position: "relative",
          zIndex: 2,
          cursor: "default",
          ...(createButtonPopover.open && {
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            bgcolor: `${theme.palette.primary.light} !important`,
          }),
          transition: "transform 0.4s ease-in-out",
        })}
        onMouseOver={handleOpen}
        ref={createButtonPopover.anchorRef}
      >
        {t("Create a Moment with this Template")}
      </LoadingButton>
    </Box>
  );
};

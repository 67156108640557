import { orderBy } from "lodash-es";
import { useMemo } from "react";
import { useSearchParams } from "react-router";
import { extractAmount } from "../../../../utils/interval";
import { ISO8601 } from "../../../../utils/iso8601";
import { MomentCardMoment } from "../../../moment-card/types";

const sortByTitle = (moment: MomentCardMoment): string | null =>
  moment.title?.toLowerCase() ?? null;

const sortBySchedule = <T extends MomentCardMoment>(moment: T): number => {
  let date;
  if (hasAtTime(moment)) {
    date = moment.attime;
  } else {
    date = moment.immediate ? moment.published_at : moment.schedule?.when;
  }

  return date ? new Date(date).getTime() : -1;
};

const sortByEvent = (moment: MomentCardMoment): string | null => {
  return moment.schedule?.event_key || null;
};

const sortByInterval = (moment: MomentCardMoment): number => {
  if (!moment.schedule?.event_key || !moment.schedule?.interval) {
    return 0;
  }

  return extractAmount(moment.schedule.interval);
};

export const defaultSortOptionMap: {
  [key: string]: {
    functions: Array<(m: MomentCardMoment) => string | number | null>;
    order: "asc" | "desc";
  };
} = {
  title: { functions: [sortByTitle], order: "asc" },
  schedule: { functions: [sortBySchedule, sortByEvent, sortByInterval, sortByTitle], order: "asc" },
};

export function useMomentSort(): <T extends MomentCardMoment>(
  moments: T[],
  options?: {
    [key: string]: {
      functions: Array<(m: MomentCardMoment) => string | number | null>;
      order: "asc" | "desc";
    };
  },
) => T[] {
  const [searchParams] = useSearchParams();
  const sortSetting = searchParams.get("sort") ?? "schedule";

  return useMemo(() => {
    return <T extends MomentCardMoment>(m: T[], options = defaultSortOptionMap) =>
      orderBy(m, options[sortSetting].functions, options[sortSetting].order);
  }, [sortSetting]);
}

function hasAtTime(v: MomentCardMoment): v is MomentCardMoment & { attime: ISO8601 } {
  return "attime" in v;
}

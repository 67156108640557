import { useMountEffect } from "@react-hookz/web";
import { useSetAtom } from "jotai";
import { useEffect } from "react";
import { useSearchParams } from "react-router";
import { useSupabase } from "../../../server/supabase/hooks";
import { UUID } from "../../../utils/uuid";
import {
    imagePreviewDataFamily,
    imagePreviewFamily,
    imageRefineAtomsAtom,
} from "../../ai-refine/store";
import { SupabaseBrandToneService } from "../../brand-kit/server/brand-tone-service";
import {
    brandToneAtom,
    chatIdAtom,
    contextQueryAtom,
    recipeQueryAtom,
    templateImageFamily,
    templateQueryAtom,
} from "../store";
import { ContextQuery } from "../types";

export function useLoadGenerateMomentPage(recipeId?: string): void {
  const [searchParams] = useSearchParams();

  const brandToneId = searchParams.get("brand_tone");

  const setTemplateQuery = useSetAtom(templateQueryAtom);
  const setBrandTone = useSetAtom(brandToneAtom);

  const setRefineAtoms = useSetAtom(imageRefineAtomsAtom);
  const setChatId = useSetAtom(chatIdAtom);
  const setContextQuery = useSetAtom(contextQueryAtom);
  const setRecipeQuery = useSetAtom(recipeQueryAtom);

  useMountEffect(() =>
    setRefineAtoms({ templateImageFamily, imagePreviewDataFamily, imagePreviewFamily }),
  );

  useSupabase(
    async ({ supabase }) => {
      const { data } = await new SupabaseBrandToneService(supabase).get(brandToneId as UUID);
      setBrandTone(data);
    },
    [brandToneId, setBrandTone],
  );

  useEffect(() => {
    void (() => {
      setTemplateQuery("");
      setContextQuery(null);

      if (searchParams.has("initialContext")) {
        setContextQuery(JSON.parse(searchParams.get("initialContext") ?? "") as ContextQuery);
        setRecipeQuery(null);
        setTemplateQuery("");
      } else if (recipeId) {
        setRecipeQuery({
          recipeId,
          answers: searchParams.getAll("answer") ?? [],
        });
        setTemplateQuery("");
        setContextQuery(null);
      } else {
        setRecipeQuery({ recipeId: "default", answers: [] });
        setTemplateQuery(searchParams.get("q") || "");
        setContextQuery(null);
      }
    })();
  }, [searchParams, setTemplateQuery, setRecipeQuery, recipeId, setChatId, setContextQuery]);
}

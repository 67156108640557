import { Box, CssBaseline, LinearProgress, styled } from "@mui/material";
import { useSetAtom } from "jotai";
import { FC, Suspense, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { Outlet, useLocation } from "react-router";
import ThemeProvider from "../../../styles/theme/theme-provider";
import { DisplayTheme, SettingsConsumer } from "../../../utils/contexts/settings-context";
import { SidebarProvider } from "../../../utils/contexts/sidebar-context";
import { CeAdminSidebar } from "./ce-admin-sidebar";
import { Sidebar, sidebarWidth } from "./sidebar/sidebar";
import { sidebarOpenAtom } from "./sidebar/store";

const LayoutRoot = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  maxWidth: "100%",
  [theme.breakpoints.up("lg")]: {
    paddingLeft: sidebarWidth,
  },
  paddingTop: 0,
}));

export const Layout: FC<{ themeOverride?: DisplayTheme }> = ({ themeOverride }) => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  const { pathname } = useLocation();
  const setSidebarOpen = useSetAtom(sidebarOpenAtom);
  useEffect(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen, pathname]);
  return (
    <SettingsConsumer>
      {({ settings }) => (
        <ThemeProvider mode={themeOverride ?? settings.theme}>
          <SidebarProvider>
            <CssBaseline />
            <Toaster />
            <LayoutRoot>
              <Box
                sx={{
                  display: "flex",
                  flex: "1 1 auto",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Suspense fallback={<LinearProgress />}>
                  <Outlet />
                </Suspense>
              </Box>
            </LayoutRoot>
            <Sidebar />
          </SidebarProvider>
        </ThemeProvider>
      )}
    </SettingsConsumer>
  );
};

export const CeAdminLayout: FC = () => {
  useEffect(() => {
    document.body.style.overflow = "auto";
  }, []);

  const { pathname } = useLocation();
  const setSidebarOpen = useSetAtom(sidebarOpenAtom);
  useEffect(() => {
    setSidebarOpen(false);
  }, [setSidebarOpen, pathname]);

  return (
    <ThemeProvider mode="ce-admin">
      <Toaster />
      <LayoutRoot>
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Outlet />
        </Box>
      </LayoutRoot>

      <CeAdminSidebar />
    </ThemeProvider>
  );
};

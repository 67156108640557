import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useNavigate } from "react-router";

const EventsPage: FC = () => {
  const navigate = useNavigate();

  useMountEffect(() => navigate("/manage/employee_data/events", { replace: true }));

  return <></>;
};

export default EventsPage;

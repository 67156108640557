import { useAuth0 } from "@auth0/auth0-react";
import { Box, BoxProps, CircularProgress, Dialog, Skeleton, Typography } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/system";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { OrgUser } from "../../server/supabase/hooks/types";
import { usePopover } from "../../utils/hooks/use-popover";
import { preloadImage } from "../../utils/image";
import { AiRefineImage } from "../ai-refine/ai-refine-image";
import { imageRefineAtomsAtom } from "../ai-refine/store";
import { brandingAtom } from "../brand-kit/store";
import { DesignHuddleModal } from "../design-huddle/design-huddle-modal";
import { useCreateDesignHuddleProject } from "../design-huddle/use-design-huddle-project-create";
import { getProject } from "../design-huddle/utils";
import {
  discoverTemplateAtom,
  discoverTemplateDHProjectFamily,
  discoverTemplateImagePageOverrideFamily,
} from "../discover/store";
import { imagePreviewDimensions } from "../generate/store";
import { LoadableTemplateImage } from "../generic/atoms/types/template";
import { TemplateImageAttribution } from "./template-image-attribution";
import { TemplateImageButton } from "./template-image-button";

export const TemplateImage: FC<
  BoxProps & {
    img?: LoadableTemplateImage;
    designHuddle?: boolean;
    loadingComponent?: ReactNode;
  }
> = ({ img, designHuddle = false, sx = [], loadingComponent, ...props }) => {
  const { t } = useTranslation();
  const [imageUrl, setImageUrl] = useState("none");
  const [highQualityReady, setHighQualityReady] = useState(false);

  const template = useAtomValue(discoverTemplateAtom);

  const pageNumber = useMemo(
    () =>
      img?.state === "hasData" && img.data?.page_number !== undefined ? img.data.page_number : 1,
    [img],
  );

  const [DHProject, setDHProject] = useAtom(
    discoverTemplateDHProjectFamily({
      slug: template?.slug,
      pageNumber,
    }),
  );

  const setPageOverride = useSetAtom(
    discoverTemplateImagePageOverrideFamily({ slug: template?.slug, pageNumber }),
  );

  const branding = useAtomValue(brandingAtom);

  const refineAtoms = useAtomValue(imageRefineAtomsAtom);

  const dimensions = useTemplateImageDimensions();

  const canRefine = refineAtoms && img?.state === "hasData" && img.data;

  const dhModal = usePopover<HTMLButtonElement>();
  const dhModalSetOpen = useCallback(
    (open: boolean) => {
      open ? dhModal.handleOpen() : dhModal.handleClose();
    },
    [dhModal],
  );

  const dhLoadingModal = usePopover<HTMLButtonElement>();

  const createProject = useCreateDesignHuddleProject();
  const handleDHOpen = useCallback(async () => {
    if (template && img?.state === "hasData" && img.data) {
      if (DHProject) {
        dhModal.handleOpen();
        return;
      }

      try {
        dhLoadingModal.handleOpen();
        const projectData = await createProject(template.img?.template_code, branding, img.data);
        const projectId = projectData?.project_id ?? null;
        if (!projectId) return;
        const project = await getProject(projectId);
        setDHProject(project);
        dhModal.handleOpen();
      } catch (error) {
        console.error(error);
        toast.error(t("Failed to create design project. Please try again."));
      } finally {
        dhLoadingModal.handleClose();
      }
    }
  }, [DHProject, branding, createProject, dhLoadingModal, dhModal, img, setDHProject, t, template]);

  const handleDHSave = useCallback(
    ({ page }: { page?: number }) => {
      // need to update template image with the page number
      if (page !== undefined) {
        setPageOverride(page);
      }

      DHProject && void getProject(DHProject?.project_id).then(setDHProject);
    },
    [DHProject, setDHProject, setPageOverride],
  );

  const { user } = useAuth0<OrgUser>();

  useEffect(() => {
    if (!img || img.state === "loading") setHighQualityReady(false);

    const lowQualityUrl =
      img?.state === "hasData" && img.data?.url.state === "hasData" ? img.data.url.data : null;
    const highQualityUrl =
      img?.state === "hasData" && img.data?.urlHighQuality?.state === "hasData"
        ? img.data.urlHighQuality.data
        : null;

    if (highQualityUrl) {
      preloadImage(highQualityUrl)
        .then(() => setHighQualityReady(true))
        .catch(() => {
          console.log("High quality preview failed");
        });
    }

    highQualityReady
      ? setImageUrl(`url(${highQualityUrl})`)
      : lowQualityUrl
        ? setImageUrl(`url(${lowQualityUrl})`)
        : () => {};
  }, [highQualityReady, img]);

  return (
    <Box
      data-testid="template-image"
      sx={[
        {
          position: "relative",
          borderRadius: "5px",
          border: "5px solid #eee",
          padding: "5px",
          ...((canRefine || designHuddle) && {
            ":hover": {
              border: "1px dashed #00000066",
              padding: "9px",
              ".MuiButton-root": {
                display: "inline-flex",
              },
            },
          }),
          overflow: "hidden",
          // add 20: 2 * (border + padding)
          width: dimensions.width + 20,
          height: dimensions.height + 20,
        },
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      {img?.state === "hasData" && img.data && (
        <>
          {canRefine && <AiRefineImage />}
          {designHuddle && (
            <>
              <TemplateImageButton
                data-analytics-id="design-huddle-template-image-edit"
                onClick={() => void handleDHOpen()}
              >
                {t("Edit")}
              </TemplateImageButton>
              {user && DHProject && (
                <DesignHuddleModal
                  open={dhModal.open}
                  setOpen={dhModalSetOpen}
                  project_id={DHProject.project_id}
                  account_id={user["http://changeengine.com/account_id"]}
                  page_number={img.data.page_number}
                  onSave={handleDHSave}
                />
              )}
              <Dialog open={dhLoadingModal.open} fullWidth>
                <Box
                  sx={{
                    height: "20vh",
                    width: "100%",
                    display: "flex",
                    gap: 4,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h6" textAlign={"center"}>
                    {t("Launching editor...")}
                  </Typography>
                  <CircularProgress />
                </Box>
              </Dialog>
            </>
          )}
        </>
      )}
      <Box
        sx={{
          width: "100%",
          height: "100%",
          boxSizing: "content-box",
          position: "relative",
          backgroundImage: imageUrl,
          backgroundSize: "cover",
        }}
        data-testid="template-image-bg"
      >
        {(img?.state !== "hasData" || img.data?.url.state !== "hasData") &&
          (loadingComponent || (
            <Skeleton sx={{ width: "100%", height: "100%" }} variant="rectangular" />
          ))}
        {img?.state === "hasData" && img?.data?.unsplashExtra && (
          <TemplateImageAttribution attributionData={img.data.unsplashExtra.attributionData} />
        )}
      </Box>
    </Box>
  );
};

export const useTemplateImageDimensions = (): { width: number; height: number } => {
  const { image_type, height, width } = useAtomValue(imagePreviewDimensions);

  const theme = useTheme();

  const isGreaterThanXl = useMediaQuery(theme.breakpoints.up("xl"));

  const isLessThanLg = useMediaQuery(theme.breakpoints.down("lg"));

  if (image_type === "banner") return { width, height };

  if (isGreaterThanXl) return { width: 400, height: 400 };

  if (isLessThanLg) return { width: 200, height: 200 };

  return { width: 250, height: 250 };
};

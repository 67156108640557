import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  SxProps,
  Tab,
  Typography,
} from "@mui/material";
import { atom, useAtom, useAtomValue } from "jotai";
import { isEmpty } from "lodash-es";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomTemplates } from "../../pages/create-content/use-custom-templates";
import { brandingAtom, brandingImageTypeAtom } from "../brand-kit/store";
import { BrandKitManager } from "../brand-kit/types";
import { ImageShapeSelect } from "./image-shape-select";
import { VisualStyleSelect } from "./visual-style-select";

/**
 * @test-usage
 */
export const customStyleInitialiazedAtom = atom<boolean>(false);

export const CustomStyleSelect: FC<{
  manager: BrandKitManager;
}> = ({ manager }) => {
  const { branding_type: globalBrandKitStyle } = useAtomValue(brandingAtom);
  const [initialized, setInitialized] = useAtom(customStyleInitialiazedAtom);

  const imageType = useAtomValue(brandingImageTypeAtom);

  const { loading, data: customTemplates, error } = useCustomTemplates();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState<"custom" | "standard" | null>("custom");

  const squares = useMemo(() => customTemplates?.["Square Comms"] ?? [], [customTemplates]);
  const banners = useMemo(() => customTemplates?.["Landscape Comms"] ?? [], [customTemplates]);

  useEffect(() => {
    if (
      !loading &&
      (squares.length > 0 || banners.length > 0) &&
      !initialized &&
      squares[0].template.template_code
    ) {
      // this is the first load of custom style select in this session. Set the default value

      manager.style.setSelected({ type: "custom", value: squares[0].template.template_code });
      setInitialized(true);
    }
  }, [
    banners.length,
    loading,
    manager.style,
    squares,
    initialized,
    setInitialized,
    globalBrandKitStyle.type,
  ]);

  useEffect(() => {
    if (initialized) {
      setSelectedTab(globalBrandKitStyle.type === "standard" ? "standard" : "custom");
    }
  }, [globalBrandKitStyle.type, initialized]);

  const selectedTemplate = useMemo(() => {
    if (!initialized) return null;
    if (globalBrandKitStyle.type === "standard") {
      return {
        name: imageType === "banner" ? "Banner" : "Square",
        id: imageType,
      };
    }

    const templates = [...squares, ...banners];
    const template = templates.find(
      (_template) => _template.template.template_code === globalBrandKitStyle.value,
    );
    return {
      name: template?.name,
      id: template?.template.template_code,
    };
  }, [
    banners,
    globalBrandKitStyle.type,
    globalBrandKitStyle.value,
    imageType,
    initialized,
    squares,
  ]);

  const handleSelectStandard = useCallback(
    (type: "square" | "banner") => () => {
      const imageStyle =
        globalBrandKitStyle.type === "standard" ? globalBrandKitStyle.value : "bold";
      manager.style.setSelected({ type: "standard", value: imageStyle });
      manager.imageType.setSelected(type);
    },
    [globalBrandKitStyle.type, globalBrandKitStyle.value, manager.style, manager.imageType],
  );

  const handleSelectCustom = useCallback(
    (type: "square" | "banner", template_code: string | null) => () => {
      if (!template_code) return;
      manager.style.setSelected({ type: "custom", value: template_code });
      manager.imageType.setSelected(type);
    },
    [manager.imageType, manager.style],
  );

  const maybeHideSx = (tab: "custom" | "standard"): SxProps => ({
    ...(selectedTab === tab ? {} : { height: 0, overflow: "hidden", padding: 0, margin: 0 }),
  });

  const selectRef = useRef<HTMLDivElement>(null);

  if (loading) return null;

  return (
    <Stack direction="row" gap={2}>
      {error || (isEmpty(squares) && isEmpty(banners)) ? (
        <>
          <ImageShapeSelect onChange={manager.imageType.setSelected} />
          <VisualStyleSelect onChange={manager.style.setSelected} />
        </>
      ) : (
        <>
          {selectedTemplate && (
            <TabContext value={selectedTab || "custom"}>
              <FormControl
                onKeyDown={(e) => {
                  if (e.code === "ArrowDown" || e.code === "ArrowUp") {
                    setTimeout(() => {
                      const focussed =
                        selectRef.current?.getElementsByClassName("Mui-focusVisible")[0];
                      if (focussed?.clientHeight === 0) {
                        setSelectedTab(selectedTab === "custom" ? "standard" : "custom");
                      }
                    }, 100);
                  }
                }}
              >
                <InputLabel htmlFor="custom-style-select">{t("Image shape")}</InputLabel>
                <Select
                  id="custom-style-select"
                  label={t("Image shape")}
                  inputProps={{ "aria-label": t("Image shape") }}
                  value={selectedTemplate.id}
                  native={false}
                  sx={{ width: "300px" }}
                  renderValue={() => (
                    <Typography variant="body1" color="text.primary">
                      {selectedTemplate?.name}
                    </Typography>
                  )}
                  MenuProps={{ ref: selectRef }}
                >
                  <Box
                    p={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      return false;
                    }}
                  >
                    <TabList
                      sx={{ paddingLeft: 1, border: "none" }}
                      onChange={(_, val: "custom" | "standard") => setSelectedTab(val)}
                    >
                      <Tab label="Your designs" value="custom" />
                      <Tab label="Standard" value="standard" />
                    </TabList>
                  </Box>

                  {!isEmpty(squares) && (
                    <ListSubheader sx={maybeHideSx("custom")}>{t("Squares")}</ListSubheader>
                  )}
                  {squares?.map(({ name, template: { template_code } }) => (
                    <MenuItem
                      key={template_code}
                      onClick={handleSelectCustom("square", template_code)}
                      value={template_code!}
                      selected={
                        globalBrandKitStyle.type === "custom" &&
                        globalBrandKitStyle.value === template_code
                      }
                      sx={maybeHideSx("custom")}
                    >
                      {name}
                    </MenuItem>
                  ))}
                  {!isEmpty(banners) && (
                    <ListSubheader sx={maybeHideSx("custom")}>{t("Banners")}</ListSubheader>
                  )}

                  {banners?.map(({ name, template: { template_code } }) => (
                    <MenuItem
                      key={template_code}
                      value={template_code!}
                      onClick={handleSelectCustom("banner", template_code)}
                      selected={
                        globalBrandKitStyle.type === "custom" &&
                        globalBrandKitStyle.value === template_code!
                      }
                      sx={maybeHideSx("custom")}
                    >
                      {name}
                    </MenuItem>
                  ))}

                  <MenuItem
                    value="square"
                    onClick={handleSelectStandard("square")}
                    selected={globalBrandKitStyle.type === "standard" && imageType === "square"}
                    sx={maybeHideSx("standard")}
                  >
                    {t("Square")}
                  </MenuItem>
                  <MenuItem
                    value="banner"
                    onClick={handleSelectStandard("banner")}
                    selected={globalBrandKitStyle.type === "standard" && imageType === "banner"}
                    sx={maybeHideSx("standard")}
                  >
                    {t("Banner")}
                  </MenuItem>
                </Select>
              </FormControl>
            </TabContext>
          )}
          {globalBrandKitStyle.type === "standard" && (
            <VisualStyleSelect onChange={manager.style.setSelected} />
          )}
        </>
      )}
    </Stack>
  );
};

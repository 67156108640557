import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BlockerFunction, useBeforeUnload, useBlocker } from "react-router";

export const LeavePageConfirm: FC<{
  children?: React.ReactNode;
  enabled: ({
    currentLocation,
    nextLocation,
    historyAction,
  }?: BlockerFunction["arguments"]) => boolean;
  slotProps?: {
    title?: {
      value?: React.ReactNode;
    };
    stayButton?: {
      value?: React.ReactNode;
    };
    proceedButton?: {
      value?: React.ReactNode;
    };
  };
  onStay?: () => void;
}> = ({ children, enabled, slotProps, onStay }) => {
  const [t] = useTranslation();
  const blocker = useBlocker(enabled);

  useBeforeUnload(
    useCallback(
      (event) => {
        if (enabled()) {
          event.stopImmediatePropagation();
          event.preventDefault();
        }
      },
      [enabled],
    ),
  );

  if (blocker.state === "blocked") {
    return (
      <Dialog open={true} onClose={() => blocker.reset?.()}>
        {slotProps?.title?.value && <DialogTitle>{slotProps.title.value}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-analytics-id="leave-page-dialog-proceed"
            variant="text"
            onClick={() => blocker.proceed?.()}
          >
            {slotProps?.proceedButton?.value ?? t("Proceed")}
          </Button>
          <Button
            data-analytics-id="leave-page-dialog-stay"
            variant="contained"
            onClick={() => {
              blocker.reset?.();
              onStay?.();
            }}
          >
            {slotProps?.stayButton?.value ?? t("Stay")}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  return null;
};

import { FilterAlt } from "@mui/icons-material";
import {
    Badge,
    Box,
    Button,
    ButtonProps,
    Divider,
    Grid2,
    Link,
    Menu,
    Tooltip,
    Typography,
} from "@mui/material";
import { ReactElement, useCallback, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Trans, useTranslation } from "react-i18next";
import { useSearchParams } from "react-router";
import { useSupabase } from "../../../server/supabase/hooks";
import { SupabaseEventService } from "../../event/server/supabase-event-service";
import {
    SupabaseProgramService,
    programsOrder,
} from "../../programs/server/supabase-program-service";
import { SupabaseSegmentService } from "../../segment/server/supabase-segment-service";
import { ChipSelect } from "./chip-select";

import { usePermissions } from "../../../server/auth/use-permissions";
import { propertyNotEmpty } from "../../../utils/not-empty";
import { useAccount } from "../../generic/hooks/use-account";
import { FilterSection } from "./filter-section";

interface MomentFilterProps {
  displayStatusOptions?: boolean;
  displayEventOptions?: boolean;
  displaySegmentOptions?: boolean;
  buttonProps?: Omit<ButtonProps, "onClick">;
}

const statuses: { [key: string]: string } = {
  draft: "Draft",
  active: "Active",
  paused: "Paused",
  finished: "Finished",
  archived: "Archived",
};

export const queryString = (
  searchParams: URLSearchParams,
  key: string,
  all: { [key: string]: string } = {},
): string[] => (searchParams.has(key) ? searchParams.getAll(key) : Object.keys(all));

const calculateFilterCount = (filterCounts: Array<[number, number]>): number => {
  return filterCounts
    .map(([selectedOptions, totalOptions]) =>
      selectedOptions !== totalOptions ? selectedOptions : 0,
    )
    .reduce((prev, curr) => prev + curr, 0);
};

//** Input for adding filters to QueryString */
export function MomentFilter({
  displayStatusOptions = true,
  displayEventOptions = true,
  displaySegmentOptions = true,
  buttonProps,
}: MomentFilterProps): ReactElement {
  const { t } = useTranslation();
  const [dialog, setDialog] = useState<boolean>(false);
  const { ce_admin } = usePermissions();
  const [programs, setPrograms] = useState<{ [key: string]: string }>({});
  const { account } = useAccount();
  const [urlSearchParams, setUrlSearchParams] = useSearchParams();
  const [searchParams, setSearchParams] = useState(urlSearchParams);
  const [events, setEvents] = useState<{ [key: string]: string }>({});
  const [segments, setSegments] = useState<{ [key: string]: string }>({});

  const promoteSlackOrIsEnabled = !account?.enable_teams || account?.enable_slack;
  const channels: { [key: string]: string } = {
    ...(promoteSlackOrIsEnabled ? { slack: "Slack" } : {}),
    work_email: "Work Email",
    personal_email: "Personal Email",
    twilio: "SMS",
    ...(account?.enable_teams ? { teams: "Teams" } : {}),
  };

  const selectedPrograms = queryString(searchParams, "program", programs);
  const selectedEvents = queryString(searchParams, "event", events);
  const selectedSegments = queryString(searchParams, "segment", segments);
  const selectedStatuses = queryString(searchParams, "status", statuses);
  const selectedChannels = queryString(searchParams, "channel", channels);

  const setSearchParam = useCallback(
    (key: string, values: string[]): void => {
      const params = new URLSearchParams(searchParams);
      params.delete(key);
      values.forEach((value) => params.append(key, value));
      setSearchParams(params);
    },
    [setSearchParams, searchParams],
  );

  const clearSearchParam = useCallback(
    (key: string): void => {
      const params = new URLSearchParams(searchParams);
      params.delete(key);
      setSearchParams(params);
    },
    [setSearchParams, searchParams],
  );

  const handleApplyFilter = useCallback(() => {
    setUrlSearchParams(searchParams);
    setDialog(false);
  }, [searchParams, setUrlSearchParams, setDialog]);

  const handleClose = useCallback(() => {
    setDialog(false);
    setSearchParams(new URLSearchParams(urlSearchParams));
  }, [setDialog, setSearchParams, urlSearchParams]);

  useSupabase(
    async ({ supabase }) => {
      const { data: programData, error: programsError } = await new SupabaseProgramService(
        supabase,
      ).getAll(null, {
        order: programsOrder,
      });
      programsError || !programData
        ? toast.error(t("Something went wrong"))
        : setPrograms(
            Object.fromEntries(
              programData.filter((x) => x.visible || ce_admin).map(({ id, title }) => [id, title]),
            ),
          );
    },
    [ce_admin, t],
  );

  useSupabase(async ({ supabase }) => {
    const { data } = await new SupabaseEventService(supabase).getKeys({ includeLibrary: true });
    if (data) {
      const eventData = Object.fromEntries(
        data
          .filter((e) => !e.calendar || e.has_schedule_event)

          .map(({ key, title }) => [key!, title!]),
      );

      setEvents(eventData);
    }
  }, []);

  useSupabase(async ({ supabase, account_id }) => {
    const { data } = await new SupabaseSegmentService(supabase).getAll(account_id, {
      filter: [
        { key: "name", operator: "neq", value: "" },
        { key: "account_id", operator: "eq", value: account_id },
      ],
      order: [{ column: "id" }],
    });

    if (data) {
      const segmentData = Object.fromEntries(
        data.filter(propertyNotEmpty("name")).map(({ id, name }) => [id, name]),
      );

      setSegments(segmentData);
    }
  }, []);

  const filterIcon = useRef();

  const filterCount = calculateFilterCount([
    [selectedSegments.length, Object.entries(segments).length],
    [selectedChannels.length, Object.entries(channels).length],
    [selectedPrograms.length, Object.entries(programs).length],
    [selectedStatuses.length, Object.entries(statuses).length],
    [selectedEvents.length, Object.entries(events).length],
  ]);

  return (
    <>
      <Box ref={filterIcon}>
        <Tooltip title={t("Filter")}>
          <Button
            data-analytics-id="moment-filter-button"
            {...buttonProps}
            onClick={() => setDialog(true)}
            sx={{ color: !filterCount ? "action.active" : null, ...buttonProps?.sx }}
            aria-label="filterButton"
          >
            <Badge badgeContent={filterCount} color="primary">
              <FilterAlt sx={{ color: !filterCount ? "action.active" : null }} />
            </Badge>
          </Button>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={filterIcon.current}
        open={dialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            color: "text.primary",
            pb: 3,
            px: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              p: 2,
            }}
          >
            {displayStatusOptions && (
              <>
                <FilterSection
                  title={"Status"}
                  options={statuses}
                  selectedItems={selectedStatuses}
                  onChange={(value) => {
                    setSearchParam("status", value);
                  }}
                />
                <Divider orientation="vertical" flexItem={true} />
              </>
            )}
            <FilterSection
              title="Channel"
              options={channels}
              selectedItems={selectedChannels}
              onChange={(value) => {
                setSearchParam("channel", value);
              }}
            />
            <Divider orientation="vertical" flexItem={true} />

            <Grid2 sx={{ display: "flex", flexDirection: "row", pt: 2.5, ml: 3 }}>
              <Grid2
                sx={{ display: "flex", flexDirection: "column", pt: 1 }}
                container
                spacing={5.5}
              >
                <Grid2>
                  <Typography variant="subtitle1" fontWeight="normal" color={"disabled.main"}>
                    Programs
                  </Typography>
                  <Link
                    component="button"
                    onClick={() => {
                      clearSearchParam("program");
                    }}
                  >
                    All
                  </Link>
                </Grid2>
                {displayEventOptions && (
                  <Grid2>
                    <Typography variant="subtitle1" fontWeight="normal" color={"disabled.main"}>
                      Events
                    </Typography>
                    <Link
                      component="button"
                      onClick={() => {
                        clearSearchParam("event");
                      }}
                    >
                      All
                    </Link>
                  </Grid2>
                )}
                {displaySegmentOptions && (
                  <Grid2>
                    <Typography variant="subtitle1" fontWeight="normal" color={"disabled.main"}>
                      Segment
                    </Typography>
                    <Link
                      component="button"
                      onClick={() => {
                        clearSearchParam("segment");
                      }}
                    >
                      All
                    </Link>
                  </Grid2>
                )}
              </Grid2>

              <Grid2 sx={{ display: "flex", flexDirection: "column", ml: 2 }} container>
                <Grid2>
                  <ChipSelect
                    title={"Programs"}
                    options={programs}
                    selectedItems={selectedPrograms}
                    onChange={(value) => {
                      setSearchParam("program", value);
                    }}
                  />
                </Grid2>
                {displayEventOptions && (
                  <Grid2>
                    <ChipSelect
                      title={"Events"}
                      options={events}
                      selectedItems={selectedEvents}
                      onChange={(value) => {
                        setSearchParam("event", value);
                      }}
                    />
                  </Grid2>
                )}
                {displaySegmentOptions && (
                  <Grid2>
                    <ChipSelect
                      title={"Segments"}
                      options={segments}
                      selectedItems={selectedSegments}
                      onChange={(value) => {
                        setSearchParam("segment", value);
                      }}
                    />
                  </Grid2>
                )}
              </Grid2>
            </Grid2>
          </Box>

          <Divider />

          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap",
              pt: 3,
            }}
          >
            <Box sx={{ flexGrow: 1 }} />

            <Button
              data-analytics-id="moment-filter-menu-cancel"
              sx={{ ml: 2 }}
              variant="outlined"
              type="reset"
              onClick={handleClose}
            >
              <Trans>Cancel</Trans>
            </Button>

            <Button
              data-analytics-id="moment-filter-menu-apply"
              type="submit"
              sx={{ ml: 2 }}
              variant="contained"
              onClick={handleApplyFilter}
            >
              <Trans>Apply Filters</Trans>
            </Button>
          </Box>
        </Box>
      </Menu>
    </>
  );
}

import { useMountEffect } from "@react-hookz/web";
import { FC } from "react";
import { useNavigateWithSearch } from "../../modules/generic/hooks/use-navigate-with-search";

const ProgramsPage: FC = () => {
  const navigate = useNavigateWithSearch();

  useMountEffect(() => {void navigate("/orchestrator", { replace: true })});

  return <></>;
};

export default ProgramsPage;

import { useSupabaseLoader } from "../../../server/supabase/hooks";
import { Account } from "../../generic/server/supabase-account-service";
import { SupabaseAnalyticsDataService } from "../server/supabase-person-service";

type AnalyticsData = {
  visitor: {
    // auth0 Id is not a uuid so string is correcter than uuid
    id?: string;
    email: string | null;
    role: string | null;
    jobTitle: string | null;
    firstName: string | null;
    lastName: string | null;
    department: string | null;
    workCountry: string | null;
    isManager: boolean | null;
    timezone: string | null;
    actingOnBehalfOf: string | null;
    auth0Id: string;
    auth0Email: string | null;
  };
  account: {
    id?: string;
    createdAt: string | null;
    accountName: string | null;
  };
};

export function useAnalyticsData(
  auth0Id: string,
  sbAccount: Account,
  role: string,
  auth0Email?: string,
): ReturnType<typeof useSupabaseLoader<AnalyticsData | null>> {
  return useSupabaseLoader(
    async ({ supabase, account_id }) => {
      const { data, error } = await new SupabaseAnalyticsDataService(supabase).getAll(account_id, {
        filter: [
          { key: "person_auth0_id", operator: "eq", value: auth0Id },
          { key: "account_id", operator: "eq", value: account_id },
        ],
        order: [{ column: "id" }],
      });

      const account: AnalyticsData["account"] = {
        id: sbAccount.business_name,
        createdAt: sbAccount.created_at,
        accountName: sbAccount.name,
      };

      let visitor: AnalyticsData["visitor"];
      if (data?.length === 2) {
        // This is a special case of a CE Admin logging into the CE account
        // it will return both their ce_admin profile and their employee_id profile
        // as both match the filters
        // We want the id from ce_admin and user data from employee_id

        visitor = {
          // id is nullable in the db types, but just in case that's null, undefined is needed for types.
          id: auth0Id,
          auth0Id: auth0Id,
          // email is nullable in the db types
          email: auth0Email ?? null,
          // This can be undefined, needs null for types
          auth0Email: auth0Email ?? null,
          role,
          actingOnBehalfOf: sbAccount.business_name,
          jobTitle: data[0].person_job_title ?? data[1].person_job_title,
          firstName: data[0].person_first_name ?? data[1].person_first_name,
          lastName: data[0].person_last_name ?? data[1].person_last_name,
          department: data[0].person_department ?? data[1].person_department,
          workCountry: data[0].person_work_country ?? data[1].person_work_country,
          isManager: data[0].person_is_manager ?? data[1].person_is_manager,
          timezone: data[0].person_timezone ?? data[1].person_timezone,
        };
      } else if (error || data.length !== 1) {
        // This will happen when the logged in user does not exist in the employee data
        // E.g. when a user is a ce admin in not-ruby, or just setting up their account
        console.warn("Error fetching analytics data", error, data);
        // Use the auth0 data for this person.
        visitor = {
          id: auth0Id,
          auth0Id: auth0Id,
          email: auth0Email ?? null, // These should be populated but juuust in case allow null.
          auth0Email: auth0Email ?? null,
          role,
          actingOnBehalfOf: sbAccount.business_name,
          // Default null fields
          jobTitle: null,
          firstName: null,
          lastName: null,
          department: null,
          workCountry: null,
          isManager: null,
          timezone: null,
        };
      } else {
        const datum = data[0];
        // Full featured response for someone found in the database
        // Using the database data for this person.
        visitor = {
          // id is nullable in the db types, but just in case that's null, undefined is needed for types.
          id: datum.id ?? undefined,
          auth0Id: auth0Id,
          // email is nullable in the db types
          email: datum.email,
          // This can be undefined, needs null for types
          auth0Email: auth0Email ?? null,
          role,
          actingOnBehalfOf: sbAccount.business_name,
          jobTitle: datum.person_job_title,
          firstName: datum.person_first_name,
          lastName: datum.person_last_name,
          department: datum.person_department,
          workCountry: datum.person_work_country,
          isManager: datum.person_is_manager,
          timezone: datum.person_timezone,
        };
      }

      return {
        visitor,
        account,
      };
    },
    [auth0Id, role, sbAccount, auth0Email],
  );
}

import { init } from "@sentry/react";
import { DevTools } from "jotai-devtools";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, createRoutesFromElements, RouterProvider } from "react-router";
import { config } from "./config";
import { Routes } from "./routes";

init(config.sentry);

const router = createBrowserRouter(createRoutesFromElements(Routes));

const root = createRoot(document.getElementById("root")!);
root.render(
  <>
    <DevTools />
    <RouterProvider router={router} />
  </>,
);

import { TabContext, TabPanel } from "@mui/lab";
import { Box, LinearProgress, Tab, Tabs, Typography } from "@mui/material";
import { useLocalStorageValue } from "@react-hookz/web";
import { useAtomValue } from "jotai";
import { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router";
import { discoverProgramAtom } from "../../modules/discover/store";
import { Page } from "../../modules/layout/components/page";
import { PageActions } from "../../modules/layout/components/page-actions";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useProgramFilters } from "../../modules/programs/hooks/use-program-filters";
import { usePopulatedProgramFromProgramId } from "../../modules/programs/hooks/use-program-from-program-id";
import { useSelectedStatusTab } from "../../modules/programs/hooks/use-selected-status-tab";
import { Breadcrumbs } from "../../modules/tabbed-programs/breadcrumbs";
import { ChannelToggle } from "../../modules/tabbed-programs/channel-toggle";
import { useProgramTabs } from "../../modules/tabbed-programs/hooks/use-program-tabs";
import { Moments } from "../../modules/tabbed-programs/moments";
import { NewMomentButton, SentMomentsButton } from "../../modules/tabbed-programs/program-header";
import { SegmentFilter } from "../../modules/tabbed-programs/segment-filter";
import { TitleFilter } from "../../modules/tabbed-programs/title-filter";
import { View, ViewToggle } from "../../modules/tabbed-programs/view-toggle";
import { UUID, uuidv4 } from "../../utils/uuid";

const TabbedProgramPage: FC = () => {
  const { t } = useTranslation();
  const { id: programId } = useParams<{ id: UUID | string }>();

  const programLoader = useAtomValue(discoverProgramAtom);
  const program = usePopulatedProgramFromProgramId(programId);
  const { filter, handleChannelFilterChange, handleSegmentFilterChange, hasFilter } =
    useProgramFilters();
  const { value: view = "grid", set: setView } = useLocalStorageValue<View>("view", {
    defaultValue: "grid",
  });

  const [, setSearchParams] = useSearchParams();
  const { selectedTab, handleTabChange } = useSelectedStatusTab();

  const programTabs = useProgramTabs(program?.id ?? undefined);

  const handleSearchChange = useCallback(
    (value: string): void => {
      setSearchParams(
        (prev) => {
          if (value) prev.set("q", value);
          else prev.delete("q");

          return prev;
        },
        { replace: true },
      );
    },
    [setSearchParams],
  );

  const title = `${program?.title || ""} | ChangeEngine`;

  if (!program || programLoader.state === "loading") return <LinearProgress />;

  const discoverSlug =
    programLoader.state === "hasData" && programLoader.data
      ? programLoader.data === "NOT_FOUND"
        ? ""
        : `/${programLoader.data.slug}`
      : "";

  return (
    <Page title={title}>
      <PageHeader>
        <Breadcrumbs
          crumbs={[
            {
              title: t("Your Programs"),
              to: "/tabbed-programs",
            },
            { title },
          ]}
        />

        <PageHeading
          heading={title}
          colour={program.theme_colour || undefined}
          icon={program.icon_value || undefined}
        ></PageHeading>
        <PageActions>
          <NewMomentButton
            discoverHref={`/discover${discoverSlug}`}
            newMomentHref={`/moments/${uuidv4()}?program_id=${program.id ?? ""}`}
          />
          <SentMomentsButton programId={program.id ?? undefined} />
        </PageActions>
      </PageHeader>
      <PageContent>
        <TabContext value={selectedTab}>
          <Box display="flex" flexWrap="wrap" gap={1} maxWidth="xl">
            <Tabs value={selectedTab} onChange={handleTabChange}>
              {programTabs.map((tab) => (
                <Tab key={`tab-${tab.value}`} value={tab.value} label={tab.label} />
              ))}
            </Tabs>

            <Box display="flex" gap={1} flexGrow={1}>
              <TitleFilter onChange={handleSearchChange} sx={{ marginLeft: "auto" }} />
              <SegmentFilter onChange={handleSegmentFilterChange} />
              <ChannelToggle onChange={handleChannelFilterChange} value={filter?.channel} />
              <ViewToggle value={view} onChange={setView} />
            </Box>
          </Box>

          {programTabs.map((tab) => (
            <TabPanel
              key={`tab-panel-${tab.value}`}
              value={tab.value}
              sx={{ "&.MuiTabPanel-root": { bgcolor: "background.paper" }, mt: 4, mx: 0, px: 0 }}
            >
              {tab.value === selectedTab && (
                <Moments
                  showCreate={tab.showCreate}
                  view={view}
                  filter={filter}
                  programId={program.id ?? undefined}
                >
                  <Typography variant="body1">{tab.emptyStateMessage}</Typography>
                  {hasFilter && (
                    <Typography variant="body1" mt={2}>
                      {t("You have set a filter which may be hiding some Moments.")}
                    </Typography>
                  )}
                </Moments>
              )}
            </TabPanel>
          ))}
        </TabContext>
      </PageContent>
    </Page>
  );
};

export default TabbedProgramPage;

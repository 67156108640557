import { Box, Link, Typography } from "@mui/material";
import { Atom, useAtomValue } from "jotai";
import { FC, memo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { toSlug } from "../../../utils/create-slug";
import { useAnalytics } from "../../analytics/analytics";
import { BrandTone } from "../../brand-kit/server/brand-tone-service";
import { BrandInfoSelected } from "../../brand-kit/types";
import { TemplateType } from "../../generic/atoms/types/template";
import { LibrarySequence, LibraryTemplate } from "../library/types";
import { DiscoverTemplate } from "../types";
import { Carousel, CarouselItemProps } from "./carousel";
import { DiscoverCard } from "./discover-card";

export const DiscoverCarousel: FC<{
  title: string;
  loading?: boolean;
  templatesAtom: Atom<LibraryTemplate[]> | Atom<LibrarySequence[]>;
  brandTone?: BrandTone;
  brandKitSelections?: BrandInfoSelected;
  Badge?: FC<{
    template: DiscoverTemplate;
    index: number;
  }>;
  slug?: string;
  subheadingGetter?: (t: DiscoverTemplate) => string | null;
}> = ({ title, templatesAtom, brandTone, brandKitSelections, Badge, slug, subheadingGetter }) => {
  const { t } = useTranslation();

  const templates = useAtomValue(templatesAtom);

  const titleTestId = `carousel-title-${toSlug(title)}`;

  if (templates.length === 0) {
    return null;
  }

  return (
    <Box minHeight={"450px"} sx={{ "& .Carousel-scrollContainer": { paddingLeft: "20px" } }}>
      {slug ? (
        <Link href={`/discover/${slug}`} sx={{ color: "text.primary" }}>
          <Typography variant="h5" data-testid={titleTestId}>
            {title}
          </Typography>
        </Link>
      ) : (
        <Typography variant="h5" fontWeight={600} mb={2} data-testid={titleTestId}>
          {t(title)}
        </Typography>
      )}

      <Carousel<DiscoverTemplate & { key: string }, ExtraProps>
        items={templates.map((template) => ({
          ...template,
          key: template.slug,
        }))}
        Item={Item}
        ItemExtraProps={{
          brandTone,
          brandKitSelections,
          Badge,
          subheadingGetter,
          carouselTitle: title,
          carouselSlug: `carousel-${title.replace(" ", "-").toLowerCase()}`,
        }}
        itemWidth="calc(max(min(300px, 20%), 250px))"
        sx={{
          "& .Carousel-scrollContainer": {
            pt: "15px", // to account for zoom on hover
          },
        }}
      />
    </Box>
  );
};

interface ExtraProps {
  Badge?: FC<{
    template: DiscoverTemplate;
    index: number;
  }>;
  brandTone?: BrandTone;
  brandKitSelections?: BrandInfoSelected;
  subheadingGetter?: (t: DiscoverTemplate) => string | null;
  carouselTitle: string;
  carouselSlug: string;
}

const Item = memo(function Item({
  value: template,
  i,
  extraProps: {
    Badge,
    brandTone,
    brandKitSelections,
    subheadingGetter,
    carouselTitle,
    carouselSlug,
  } = {} as ExtraProps,
}: CarouselItemProps<DiscoverTemplate, ExtraProps | undefined>) {
  const navigate = useNavigate();
  const { gaEvent } = useAnalytics();

  const handleClick = (): void => {
    gaEvent("discover_carousel", {
      action: "click",
      type: carouselSlug,
      value: template.title,
    });

    const pathname =
      template.type === TemplateType.moment
        ? `/discover/${template.program.slug}/${template.slug}`
        : `/discover/sequence/${template.slug}/${template.templates[0].slug}`;
    void navigate({
      pathname,
      ...(brandTone
        ? {
            search: `?${new URLSearchParams({
              brand_tone: brandTone?.id,
            }).toString()}`,
          }
        : {}),
    });
  };

  return (
    <DiscoverCard
      template={template}
      onClick={handleClick}
      brandKitSelections={brandKitSelections}
      data-testid={carouselTitle + template.slug}
      sx={{
        overflowY: "visible",
        marginRight: 2,
      }}
      badge={Badge && <Badge template={template} index={i} />}
      subheadingGetter={subheadingGetter}
    />
  );
});

import { yupResolver } from "@hookform/resolvers/yup";
import {
    Alert,
    Box,
    Button,
    Checkbox,
    Collapse,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import { CeAdminSupabaseAccountService } from "../../modules/generic/server/supabase-account-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useSupabaseCallback } from "../../server/supabase/hooks";
import { uuidv4 } from "../../utils/uuid";
import yup from "../../utils/yup";
import CeAdminPageHeader from "./ce-admin-page-header";

const schema = yup.object().shape({
  account_display_name: yup.string().required("Display name is required").default(""),
  account_name: yup.string().required("Login name is required").default(""),
  account_business_name: yup.string().required("Business name is required").default(""),
  enable_slack: yup.boolean().default(false),
  email_header: yup.string().default(""),
  email_footer: yup.string().default(""),
  account_type: yup
    .string()
    .oneOf(["CLIENT", "LIBRARY", "DEMO", "TESTING"])
    .required("Account type is required")
    .default("CLIENT"),
});

type Schema = yup.InferType<typeof schema>;

const CreateAccount: FC = () => {
  const [showAdvanced, setShowAdvanced] = useState(false);
  const navigate = useNavigate();

  const { control, setValue, handleSubmit } = useForm({
    defaultValues: schema.cast({}),
    resolver: yupResolver(schema),
  });

  const createAccount = useSupabaseCallback<[Schema], void, "ce_admin">(
    async ({ supabase }, data: Schema) => {
      const { error } = await new CeAdminSupabaseAccountService(supabase).createAccount({
        ...data,
        account_id: uuidv4(),
        create_auth0_account: true,
      });

      if (error) toast.error(error.message);
      else {
        toast.success("Account created successfully");
        void navigate("/ce_admin");
      }
    },
    [navigate],
    "ce_admin",
  );
  return (
    <Page title={"Administration | ChangeEngine"}>
      <PageHeader>
        <PageHeading
          heading={<CeAdminPageHeader />}
          subheading={"Create a New ChangeEngine Account"}
        />
      </PageHeader>
      <PageContent>
        <Alert severity="info" sx={{ mb: 2 }}>
          <Stack gap={2}>
            <Typography>Using this form will create a new account on the above shard</Typography>
            <Typography>
              If you wish to create an account on a different shard, please first log in to an
              existing account on that shard and return to this page
            </Typography>
          </Stack>
        </Alert>
        <form
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSubmit={handleSubmit(createAccount, (errors) => console.log({ errors }))}
        >
          <Paper elevation={24} sx={{ p: 5 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Account Information
            </Typography>

            <Controller
              name="account_display_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  label="Company Display Name"
                  fullWidth
                  variant="standard"
                  onChange={(newVal) => {
                    field.onChange(newVal.target.value);
                    setValue(
                      "account_name",
                      newVal.target.value.toLowerCase().replaceAll(" ", "-"),
                      {
                        shouldValidate: true,
                      },
                    );
                  }}
                  helperText={error?.message ?? "The company's actual name"}
                  error={Boolean(error)}
                />
              )}
            />
            <Controller
              name="account_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Company Login Name"
                  fullWidth
                  value={field.value ?? ""}
                  variant="standard"
                  helperText={
                    error?.message ?? "The company's login name used to identify with Auth0"
                  }
                  error={Boolean(error)}
                />
              )}
            />
            <Controller
              name="account_business_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Company Business Name"
                  fullWidth
                  value={field.value ?? ""}
                  variant="standard"
                  helperText={error?.message ?? "The Business Name (used for grouping Pendo)"}
                  error={Boolean(error)}
                />
              )}
            />
          </Paper>

          <Collapse in={showAdvanced}>
            <Paper elevation={24} sx={{ p: 5, mt: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Advanced Options
              </Typography>

              <Controller
                name="account_type"
                control={control}
                render={({ field }) => (
                  <FormControl variant="standard" fullWidth>
                    <InputLabel id="domain-select-label">Account Type</InputLabel>
                    <Select
                      id="account-type-select"
                      label="Account Type"
                      value={field.value}
                      onChange={field.onChange}
                    >
                      <MenuItem value="CLIENT">Client</MenuItem>
                      <MenuItem value="DEMO">Demo</MenuItem>
                      <MenuItem value="TESTING">Testing</MenuItem>
                    </Select>
                  </FormControl>
                )}
              />

              <Controller
                name="email_header"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email Header"
                    fullWidth
                    variant="standard"
                    value={field.value}
                    onChange={field.onChange}
                    multiline
                    rows={3}
                    helperText="This needs to be copied from Unlayer"
                  />
                )}
              />
              <Controller
                name="email_footer"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Email Footer"
                    fullWidth
                    variant="standard"
                    value={field.value}
                    onChange={field.onChange}
                    multiline
                    rows={3}
                    helperText="This needs to be copied from Unlayer"
                  />
                )}
              />
              <Controller
                name="enable_slack"
                control={control}
                render={({ field }) => (
                  <Box sx={{ mt: 2 }}>
                    <FormControlLabel
                      control={<Checkbox checked={field.value} onChange={field.onChange} />}
                      label="Force Enable Slack (this allows creating Slack moments straight away, but will still require actually connecting Slack within CE before they can be sent)"
                    />
                  </Box>
                )}
              />
            </Paper>
          </Collapse>

          <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
            <Button
              data-analytics-id={`ce-admin-${showAdvanced ? "hide" : "show"}-advanced`}
              type="button"
              variant="text"
              onClick={() => setShowAdvanced(!showAdvanced)}
            >
              {showAdvanced ? "Hide" : "Show"} Advanced Options
            </Button>

            <Button data-analytics-id="ce-admin-create-account" type="submit" variant="contained">
              Create Account
            </Button>
          </Box>
        </form>
      </PageContent>
    </Page>
  );
};

export default CreateAccount;

import {
  ArticleOutlined,
  PhotoAlbumOutlined,
  QuizOutlined,
  RectangleRounded,
  SquareRounded,
  TvOutlined,
  VideoCameraFrontOutlined,
  WorkspacePremiumOutlined,
} from "@mui/icons-material";
import { Grid2, IconButton, Stack, Typography } from "@mui/material";
import { FC, useCallback } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router";

export const EmptyState: FC = () => {
  const navigate = useNavigate();

  const handleClick = useCallback(
    (format: string) => {
      const params = new URLSearchParams({ format });

      void navigate({ pathname: "/create-content", search: params.toString() });
    },
    [navigate],
  );

  return (
    <Stack gap={6} textAlign="center" maxWidth="md" mx="auto" alignItems="center">
      <svg xmlns="http://www.w3.org/2000/svg" width="130px" viewBox="0 0 24 24">
        <defs>
          <linearGradient id="Gradient1">
            <stop className="stop1" offset="8.45%" />
            <stop className="stop2" offset="106.53%" />
          </linearGradient>
        </defs>
        <style>
          {`
            .stop1 {
                stop-color: #00B6EF;
            }
            .stop2 {
                stop-color: #DF1C8E;
            }
            `}
        </style>
        <path
          d="M9 13h10l-3.45-4.5l-2.3 3l-1.55-2zm-6 8q-.825 0-1.412-.587T1 19V6h2v13h17v2zm4-4q-.825 0-1.412-.587T5 15V4q0-.825.588-1.412T7 2h5l2 2h7q.825 0 1.413.588T23 6v9q0 .825-.587 1.413T21 17zm0-2h14V6h-7.825l-2-2H7zm0 0V4z"
          fill="url(#Gradient1)"
        />
      </svg>
      <Trans>
        <Typography variant="h5">You haven&apos;t saved any content yet</Typography>
        <Typography variant="body1">
          When you create content with ChangeEngine, you&apos;ll be able to save it here to reuse at
          any time. Use the &quot;Save&quot; button on the edit screen.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          (Excludes Moments, which you can find on the home page or in &quot;Sent Moments&quot;)
        </Typography>
        <Typography variant="h6">Create New Content</Typography>
        <Grid2 container rowSpacing={4}>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-faq"
              onClick={() => {
                handleClick("Employee FAQ");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <QuizOutlined />
                <Typography variant="body2" color="textPrimary">
                  FAQ
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-poster"
              onClick={() => {
                handleClick("Poster");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <PhotoAlbumOutlined />
                <Typography variant="body2" color="textPrimary">
                  Poster
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-tv-display"
              onClick={() => {
                handleClick("TV Display");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <TvOutlined />
                <Typography variant="body2" color="textPrimary">
                  TV Display
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-landscape-logo"
              onClick={() => {
                handleClick("Logo Landscape");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <RectangleRounded />
                <Typography variant="body2" color="textPrimary">
                  Landscape Logo
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-certificate"
              onClick={() => {
                handleClick("Certificate");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <WorkspacePremiumOutlined />
                <Typography variant="body2" color="textPrimary">
                  Certificate
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-square-logo"
              onClick={() => {
                handleClick("Logo Square");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <SquareRounded />
                <Typography variant="body2" color="textPrimary">
                  Square Logo
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-manager-one-pager"
              onClick={() => {
                handleClick("Manager One-Pager");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <ArticleOutlined />
                <Typography variant="body2" color="textPrimary">
                  Manager One-Pager
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
          <Grid2 size={3}>
            <IconButton
              data-analytics-id="asset-library-create-zoom-background"
              onClick={() => {
                handleClick("Zoom Background");
              }}
            >
              <Stack gap={3} display="flex" alignItems="center">
                <VideoCameraFrontOutlined />
                <Typography variant="body2" color="textPrimary">
                  Zoom Background
                </Typography>
              </Stack>
            </IconButton>
          </Grid2>
        </Grid2>
      </Trans>
    </Stack>
  );
};

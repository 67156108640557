import { Box, BoxProps, Link, Typography, styled } from "@mui/material";
import { useAtomValue } from "jotai";
import { FC } from "react";
import { useParams } from "react-router";
import { UUID } from "../../utils/uuid";
import {
    SequenceBarMoment as SequenceBarMomentType,
    sequenceMomentAtom,
} from "../layout/components/store";
import { SequenceMoment } from "../moment/server/supabase-moment-service";

export const SequenceBarMoment: FC<{ momentId: UUID | null } & BoxProps> = ({
  momentId,
  ...props
}) => {
  const { id } = useParams<{ id: UUID }>();
  const moment = useAtomValue(sequenceMomentAtom(momentId));
  if (!momentId) return null;

  return id === momentId ? (
    <Inner moment={moment} {...props} selected={true} />
  ) : (
    <Link
      href={`/moments/${momentId as string}${moment?.barStatus === "new" ? `?sequence_id=${moment.sequence_id}` : ""}`}
      key={momentId}
      underline="none"
    >
      <Inner moment={moment} {...props} />
    </Link>
  );
};

const Inner: FC<{ moment: SequenceBarMomentType | null; selected?: boolean } & BoxProps> = ({
  moment,
  sx = [],
  selected = false,
  ...props
}) => {
  if (!moment || moment.barStatus === "archived") return null;

  return (
    <Box
      sx={[
        (theme) => ({
          borderRadius: `${theme.shape.borderRadius}px`,
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: selected ? theme.palette.primary.main : "transparent",
          ":hover": {
            borderColor: theme.palette.primary.main,
          },
          overflow: "hidden",
          position: "relative",
        }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      <Box
        sx={[
          (theme) => ({
            minHeight: `var(--sb-thumb-width)`,
            boxShadow: "0px 1px 1px 0px rgba(0,0,0,0.2)",
            display: "grid",
            gridTemplateColumns: "var(--sb-thumb-width) 1fr",
            gridAutoRows: "1fr",
            gap: 2,

            pr: 2,
            backgroundColor: theme.palette.background.default,
            ":hover": {
              bgcolor: theme.palette.action.hover,
            },
            color: theme.palette.text.secondary,
            transition: "all 0.2s ease-out",

            position: "relative",
          }),
        ]}
      >
        <Box
          sx={{
            height: "var(--sb-thumb-width)",
          }}
        >
          {moment.cover_image_path ? (
            <Thumbnail src={moment.cover_image_path ?? ""} alt="" />
          ) : (
            <Thumbnail src="/placeholder.svg" width={"100%"}></Thumbnail>
          )}
        </Box>

        <Typography
          variant="body2"
          flexGrow={1}
          py={0.5}
          fontWeight={500}
          height="var(--sb-thumb-width)"
        >
          {moment.title}
        </Typography>
      </Box>
      <StatusChip status={moment.status} sx={{ position: "absolute", bottom: 8, right: 8 }} />
    </Box>
  );
};

const StatusChip: FC<{ status: SequenceMoment["status"] } & BoxProps> = ({
  status,
  sx = [],
  ...props
}) => {
  return (
    <Box
      sx={[
        (theme) => ({
          minWidth: "6em",
          px: theme.spacing(1),
          textAlign: "center",
          textTransform: "uppercase",
          bgcolor: theme.palette.background.default,
          padding: "2px 4px",
          borderRadius: "8px",
          fontSize: "0.6rem",
          border: `1px solid ${theme.palette.status[status ?? "paused"]}`,
          color: theme.palette.status[status ?? "paused"],
        }),
        ...(sx instanceof Array ? sx : [sx]),
      ]}
      {...props}
    >
      {status}
    </Box>
  );
};

const Thumbnail = styled("img")({
  width: "var(--sb-thumb-width)",
  height: "var(--sb-thumb-width)",
  objectFit: "cover",
});

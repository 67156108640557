import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { CopyDialogProps } from "./copy-dialog";

export const CopyWarningDialog: FC<CopyDialogProps> = ({ id, open, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("Clone this Moment with Newsletter editor?")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "You are about to clone this Moment using the Newsletter Editor. This is discouraged in this use case. " +
              "We recommend starting from scratch with our newer editor so that you can take advantage of our AI and Image creation tools.",
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          data-analytics-id="newlsetter-copy-warning-dialog-cancel"
          color="error"
          onClick={onClose}
        >
          {t("Cancel")}
        </Button>
        <Button
          data-analytics-id="newlsetter-copy-warning-dialog-copy"
          color="warning"
          onClick={() => {void navigate(`/moments/copy/${id}`)}}
        >
          {t("Copy")}
        </Button>
        <Button
          data-analytics-id="newlsetter-copy-warning-dialog-copy-without-message"
          color="primary"
          onClick={() => {void navigate(`/moments/copy/${id}?no_message=1`)}}
        >
          {t("Copy Without Message")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

import { Alert, Box, Button, CircularProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { redirect } from "react-router";
import { AssetCount } from "../../modules/assets/asset-count";
import { AssetGrid } from "../../modules/assets/asset-grid";
import { AssetPreviewModal } from "../../modules/assets/asset-preview-modal";
import { EmptyState } from "../../modules/assets/empty-state";
import { useAssets } from "../../modules/assets/hooks/use-assets";
import { Asset } from "../../modules/assets/server/digital-asset-service";
import { Page } from "../../modules/layout/components/page";
import { PageContent } from "../../modules/layout/components/page-content";
import { PageHeader } from "../../modules/layout/components/page-header";
import { PageHeading } from "../../modules/layout/components/page-heading";
import { useFlag } from "../../server/optimizely";
import { useDialog } from "../../utils/hooks/use-dialog";
import { AutoFixHigh } from "@mui/icons-material";

const AssetLibraryPage: FC = () => {
  const { t } = useTranslation();
  const [enabled, , ready] = useFlag("asset_library");

  const { data, loading, error, reload } = useAssets();

  const previewModal = useDialog();

  const [selectedAsset, setSelectedAsset] = useState<Asset | null>(null);
  const handleSelectAsset = useCallback(
    (asset: Asset) => {
      setSelectedAsset(asset);
      previewModal.handleOpen();
    },
    [previewModal],
  );

  const handleDeleted = useCallback(() => {
    previewModal.handleClose();
    reload();
  }, [previewModal, reload]);

  if (ready && !enabled) {
    redirect("/404");
  }

  return (
    <>
      <Page title={t("Your Content Library")}>
        <PageHeader>
          <Box display="flex" justifyContent="space-between">
            <PageHeading heading={t("Your Content Library")} />
            <Button
              variant="contained"
              color="primary"
              href="/create-content"
              data-analytics-id="content-library-empty-state-cta"
              startIcon={<AutoFixHigh />}
            >
              Create Content
            </Button>
          </Box>
        </PageHeader>

        <PageContent maxWidth="xl">
          {(!ready || loading) && <CircularProgress />}
          {error && (
            <Alert severity="error">
              {t("Sorry, there was a problem retrieving your content. Please try again later")}
            </Alert>
          )}
          {data && data.length > 0 && (
            <Stack spacing={4} pt={2}>
              <AssetCount assets={data} />
              <AssetGrid assets={data} onClick={handleSelectAsset} />
            </Stack>
          )}
          {data && data.length === 0 && <EmptyState />}
        </PageContent>
      </Page>
      <AssetPreviewModal
        asset={selectedAsset}
        open={previewModal.open}
        onClose={previewModal.handleClose}
        onChange={reload}
        onDeleted={handleDeleted}
      />
    </>
  );
};

export default AssetLibraryPage;

import { useEffect } from "react";
import { useNavigate } from "react-router";
import { UseMomentProperties } from "../../moment-card/types";
import { getTargetType } from "../../to/use-to-target";
import { isEmailChannel } from "../component/channel/channel";
import { MomentFormMoment } from "../types/moment-form-moment";
import { getMomentEditStatus } from "./use-moment-edit-status";

export function useNewsletterRedirect(moment: MomentFormMoment, newsletter?: boolean): void {
  const navigate = useNavigate();

  const status = getMomentEditStatus(moment);

  useEffect(() => {
    if (status === "new") return;

    const shouldUseNewsletter = isNewsletter(moment);

    if (newsletter && !shouldUseNewsletter) {
      void navigate(`/moments/${moment.id}`, { replace: true });
    } else if (!newsletter && shouldUseNewsletter) {
      void navigate(`/moments/${moment.id}/newsletter`, { replace: true });
    }
  }, [moment, navigate, newsletter, status]);
}

export function isNewsletter(
  moment: Pick<
    UseMomentProperties,
    | "use_lexical_editor"
    | "channel"
    | "only_additional_recipients"
    | "target_manager"
    | "target_connection_type"
    | "target_channel"
    | "notify_channel"
  >,
): boolean {
  const { toTarget } = getTargetType(moment);

  return (
    !moment.use_lexical_editor &&
    (!moment.channel || isEmailChannel(moment.channel)) &&
    toTarget === "employee"
  );
}

import { Box, BoxProps, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useBrandKit } from "../../../brand-kit/hooks/use-brand-kit";
import { BrandKitColor } from "../../../brand-kit/server/brand-kit-service";

export const BrandColours: FC<
  { onClick: (color: BrandKitColor) => void } & Omit<BoxProps, "onClick">
> = ({ onClick, ...props }) => {
  const { brandKit } = useBrandKit();
  const colors = brandKit?.choices?.colors;

  if (!colors) return null;

  return (
    <Box
      sx={(theme) => ({
        border: `1px solid ${theme.palette.grey[300]}`,
        borderRadius: "5px",
        py: theme.spacing(2),
        px: theme.spacing(1),
        position: "relative",
      })}
    >
      <Typography variant="body2" mt={"-2em"} bgcolor="background.paper" position="absolute">
        Your brand
      </Typography>
      <Box display={"grid"} gap={1} gridTemplateColumns={"repeat(4, 1fr)"} {...props}>
        {colors.map(
          (color) =>
            color.hex && (
              <Tooltip key={color.hex} title={color.hex} placement="bottom" arrow>
                <Box
                  key={color.hex}
                  sx={(theme) => ({
                    width: "100%",
                    paddingTop: "100%",
                    borderRadius: "5px",
                    backgroundColor: color.hex,
                    cursor: "pointer",
                    borderWidth: "1px",
                    borderStyle: "solid",
                    borderColor: theme.palette.grey[300],
                    "&:hover": {
                      borderColor: theme.palette.primary.main,
                    },
                  })}
                  onClick={() => onClick(color)}
                />
              </Tooltip>
            ),
        )}
      </Box>
    </Box>
  );
};

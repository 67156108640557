import { BookmarkAddOutlined, CheckCircleOutline, Download, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, IconButton, Link } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { t } from "i18next";
import { FC, useCallback, useState } from "react";
import toast from "react-hot-toast";
import { Trans } from "react-i18next";
import { useFlag } from "../../server/optimizely";
import { useDialog } from "../../utils/hooks/use-dialog";
import { DesignHuddleEditorWithBranding } from "../design-huddle/design-huddle-editor-with-branding";
import {
  DesignHuddleExportModal,
  ExportHandler,
} from "../design-huddle/design-huddle-export-modal";
import { Editor, EditorReadyHandler } from "../design-huddle/types";
import { LeavePageConfirm } from "../generic/components/leave-page-confirm";
import { EditableField } from "./editable-field";
import { useSaveAssetCallback } from "./hooks/use-save-asset-callback";
import { SaveAssetHandler } from "./types";

export const EditContent: FC<{
  projectId: string;
  page: number;
  onReady: () => void;
  title: string;
  onSave?: SaveAssetHandler;
  assetId?: string;
}> = ({ projectId, page, onReady, title, onSave, assetId }) => {
  const [assetLibrary] = useFlag("asset_library");

  const [filename, setFilename] = useState(title);
  const exportModal = useDialog();

  const [editor, setEditor] = useState<Editor | null>(null);

  const [editable, setEditable] = useState(false);

  const handleExport: ExportHandler = useCallback(({ filename: f }) => {
    setFilename(f);
  }, []);

  const saveAsset = useSaveAssetCallback({
    editor,
    onSave,
    title: filename,
    projectId,
    page,
  });

  const [editorDirty, setEditorDirty] = useState(assetId ? false : true);
  const [saving, setSaving] = useState(false);
  const handleSave = useCallback(() => {
    let savingToast: string | undefined;
    void (async () => {
      try {
        setSaving(true);
        savingToast = toast.custom(
          <Alert severity="info" variant="outlined" sx={{ bgcolor: "white" }}>
            {t("Saving asset...")}
          </Alert>,
          { duration: 2000 },
        );
        const asset = await saveAsset();
        toast.dismiss(savingToast);
        if (asset) {
          setEditorDirty(false);
          toast.custom(<SuccessToast />, { duration: 2000 });
        } else throw new Error("Failed to save asset");
      } catch (e: unknown) {
        toast.custom(<ErrorToast />, { duration: 2000 });
        console.error(e);
      } finally {
        setSaving(false);
      }
    })();
  }, [saveAsset]);

  const handleReady: EditorReadyHandler = useCallback(
    (e) => {
      setEditor(e);
      onReady();
    },
    [onReady],
  );

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Stack
        data-testid="edit-content"
        direction="row"
        p={2}
        justifyContent="space-between"
        alignItems="center"
        gap={2}
      >
        <Stack ml={2} gap={2} direction="row" alignItems="baseline">
          <EditableField
            title={filename}
            editable={editable}
            setEditable={setEditable}
            setFilename={setFilename}
            onChange={(newTitle: string) => void onSave?.({ title: newTitle })}
          />
          <IconButton
            data-analytics-id="edit-content-edit-name"
            size="small"
            data-testid="edit-content-edit-name-button"
            onClick={() => {
              setEditable(true);
            }}
          >
            <Edit fontSize="small" />
          </IconButton>
        </Stack>
        <Stack direction="row" gap={2}>
          {assetLibrary && (
            <LoadingButton
              data-analytics-id="edit-content-save-asset"
              variant="outlined"
              color={editorDirty ? "primary" : "secondary"}
              size="large"
              startIcon={editorDirty ? <BookmarkAddOutlined /> : <CheckCircleOutline />}
              onClick={editorDirty ? handleSave : undefined}
              loading={saving}
            >
              {editorDirty ? t("Save") : t("Saved")}
            </LoadingButton>
          )}
          <Button
            data-analytics-id="edit-content-export-image"
            variant="contained"
            size="large"
            startIcon={<Download />}
            onClick={exportModal.handleOpen}
          >
            {t("Export")}
          </Button>
        </Stack>
      </Stack>
      <Box flexGrow={1}>
        {projectId !== "dummy" && (
          <DesignHuddleEditorWithBranding
            project_id={projectId}
            page_number={page.toString()}
            hide_right_panel
            onReady={handleReady}
            onChange={() => setEditorDirty(true)}
          />
        )}
      </Box>
      <DesignHuddleExportModal
        projectId={projectId}
        page={page}
        dialog={exportModal}
        filename={filename}
        setFilename={setFilename}
        onExport={handleExport}
        saveHandler={handleSave}
      />
      <LeavePageConfirm
        enabled={() => editorDirty}
        slotProps={{
          title: { value: t("Save your work before exiting") },
          proceedButton: { value: t("Exit without saving") },
          stayButton: { value: t("Save") },
        }}
        onStay={handleSave}
      >
        {t(
          "Save this design to your Content Library so you can view, edit, and duplicate it later.",
        )}
      </LeavePageConfirm>
      ;
    </Box>
  );
};

const SuccessToast: FC = () => {
  return (
    <Alert severity="success" variant="outlined">
      <Trans>
        Saved to your <Link href="/content-library">Content Library</Link>
      </Trans>
    </Alert>
  );
};

const ErrorToast: FC = () => {
  return (
    <Alert severity="error" variant="outlined">
      <Trans>There was an error saving your asset. Please try again later.</Trans>
    </Alert>
  );
};
